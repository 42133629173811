@import '../../../styles/theme/theme';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--hcx-field);
  padding: $hcx-spacing * 1.5 $hcx-spacing;
  max-height: 48px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &:hover {
    cursor: pointer;

    .columnIcon {
      fill: #FFF;
    }

    .edit {
      transition: opacity 0.2s;
      opacity: 1;
    }
  }

  &.active {

    .columnIcon {
      fill: var(--hcx-blue);
    }

    .title {
      color: var(--hcx-blue);
    }

    .edit svg {
      fill: var(--hcx-blue);
    }
  }
}

.columnIcon {
  fill: var(--hcx-icon-default);
}

.title {
  margin-left: $hcx-spacing / 2;
  font-weight: 600;
  flex: 1;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit {
  opacity: 0;
}

.photoInput {
  width: 120px;
  height: 120px;
}

.descriptionText {
  font-size: 12px;
  color: var(--hcx-font-secondary);
  margin-bottom: 19px;
}

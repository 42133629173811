@import 'styles/theme/theme';
@import '../account-profile.shared';

.label {
  @extend .profileBlockLabel;

  margin-bottom: 17px;
  margin-top: $hcx-spacing * 4;
}

.link {
  display: flex;
  align-items: center;
  padding: $hcx-spacing $hcx-spacing * 4;
  color: var(--hcx-font-primary);
  background-color: transparent;
  transition: background-color 0.3s;
  cursor: pointer;
  position: relative;
  width: 100%;

  & > svg,
  &::before {
    position: absolute;
    top: 50%;
    left: $hcx-spacing * 2;
    transform: translate(-50%, -50%);
  }

  &::before {
    content: ' ';
    width: 4px;
    height: 4px;
    background-color: var(--hcx-blue);
    display: none;
  }

  & > svg {
    width: 14px;
    height: 14px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover,
  &.active {
    background-color: #1B202C;
  }

  &.active {
    color: var(--hcx-blue);

    & > svg {
      display: none;
    }

    &::before {
      display: block;
    }
  }

  &:not(.active):hover {

    & > svg {
      opacity: 1;
    }
  }
}

.scrollbar {
  width: calc(100% + #{$hcx-spacing * 4}) !important;
  margin-left: -$hcx-spacing * 4;
}

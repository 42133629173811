@import 'styles/theme/theme';

.row {
  cursor: pointer;
}

.selectedRow {
  background-color: var(--hcx-field);
}

.prefixDot {
  width: 6px;
  height: 6px;
  background-color: var(--hcx-blue);
  position: relative;
  border-radius: 50%;
  right: 2 * $hcx-spacing;
}

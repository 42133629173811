@import 'styles/theme/theme';

.root {
  margin-bottom: 2 * $hcx-spacing;
}

.title {
  margin-bottom: 1.5 * $hcx-spacing;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.gridItemTitle {
  margin-bottom: 0.5 * $hcx-spacing;
}

.content {
  position: relative;
}

.cancelButtonWrapper {
  position: absolute;
  padding: 3px 10px;
  line-height: 15px;
  right: 20px;
  bottom: 0;
}

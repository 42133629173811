@import 'styles/theme/utils';

.container {
  width: 237px;
  height: 42px;
  background-color: var(--hcx-background);
  border-radius: 100px;

  @include flex-center;
}

.empty {
  color: var(--hcx-icon-primary);
}

.red {
  color: #FF6A6A !important;
}

.cell {
  margin: 0 12px;
}

.number {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFF;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: #6D7B99;
}

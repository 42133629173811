@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5 * $hcx-spacing;
  width: 58px;
  height: 73px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    background-color: #1C2537;
  }
}

.contractWrapper {
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: var(--hcx-font-primary);
  margin-top: 7px;
  max-width: 100%;
}

.contract {
  display: block;
  text-align: center;

  @include ellipsis;
}

// @import '../hcx-card-buy_sell/buy_sell-tab-template/buy_sell-tab-template.module.scss';
@import 'styles/theme/theme';

.bidInfo {
  display: flex;
  margin-bottom: 25px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4.5 * $hcx-spacing;
}

.submitBlock {
  align-self: flex-end;
  margin-top: 6 * $hcx-spacing;
}

.amount {
  display: flex;
  flex-direction: row-reverse;
}

.bidInfoTable {
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-primary);

  &:first-child {
    margin-right: 50px;
  }

  th {
    color: var(--hcx-font-secondary);
    padding-right: 8px;
  }

  tbody > tr:first-child {

    td,
    th {
      padding-bottom: 10px;
    }
  }
}

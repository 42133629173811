.widthLinkedin {
  width: 332px;
}

.colorBlue {
  color: var(--hcx-blue);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px;
  cursor: pointer;
}

.firstRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  margin-bottom: 30px;
}

.resumeUrlError {
  position: absolute;
  width: max-content;
  bottom: -22px;
  left: 0;
  color: var(--hcx-input-text-error);
}

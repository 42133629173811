@import 'styles/theme/theme';

.root {
  width: 100%;
}

.tabs {
  padding: 3.5 * $hcx-spacing 0 0 3 * $hcx-spacing;
}

.nullStateIcon {
  height: 198px;
  width: 198px;
}

.root {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  background-color: #151524;
  color: var(--hcx-font-primary);
}

.up {
  color: var(--hcx-alert-up);
}

.down {
  color: var(--hcx-alert-down);
}

.term {
  color: var(--hcx-font-secondary);
}

.action {
  padding: 4px 13px;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
}

@import 'styles/theme/theme';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo {
  margin-bottom: 6.4 * $hcx-spacing;
  width: 215px;
}

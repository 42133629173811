@import 'styles/theme/theme';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: $hcx-spacing;
  margin-bottom: $hcx-spacing * 2.5;
}

.headerTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.activityButton {
  margin: 0 10px 0 15px;
  width: 24px;
  height: 24px;
}

.closeButton {
  color: white;
  width: 24px;
  height: 24px;
}

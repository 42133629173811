@import 'styles/theme/theme';
@import 'styles/colors';
@import '../account-profile.shared';

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $hcx-spacing * 3 $hcx-spacing * 5 $hcx-spacing * 3 $hcx-spacing * 5;
}

.starsContainer {
  width: 100%;
}

.title {
  line-height: 16px;
  margin-bottom: 6px;
}

.image {
  width: 100%;
  height: 100%;
  max-width: 64px;
  max-height: 64px;
}

.iconColor {
  color: var(--hcx-font-raiting);
}

.raitingText {
  color: var(--hcx-font-raiting);
}

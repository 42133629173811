@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  padding: $hcx-spacing $hcx-spacing 0 $hcx-spacing;
  display: flex;
  min-width: 0;
}

.photoWrapper {
  position: relative;
  margin-right: $hcx-spacing;
  width: 175px;
  min-width: 175px;
  height: 150px;

  .photo {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    margin-right: $hcx-spacing;
  }

  .rating {
    position: absolute;
    right: 2px;
    top: 4px;
  }
}

.info {
  color: var(--hcx-font-primary);
  padding-top: 2px;
  min-width: 0;
  text-align: start;

  & > * {
    text-align: start;

    @include ellipsis;
  }

  .nameWrapper {
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-transform: capitalize;

    @include ellipsis;
  }

  .contractId {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 12px;
    background: #243F7A;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: fit-content;
    margin: 4px 0 10px;
  }

  .careerRow {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

    & > span {
      color: #BFBFBF;
    }
  }
}

.small {
  padding-top: 0;
  align-items: center;

  .resultLine {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: var(--hcx-link);
    margin-left: $hcx-spacing;
  }

  .photoWrapper {
    height: 32px;
    width: 32px;
    min-width: 32px;

    .photo {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
      margin-right: $hcx-spacing;
    }
  }

  .info {
    padding: 0;
  }

  .name {
    font-size: 16px;
    line-height: 22px;
  }

  .rating {
    position: static;
    margin-left: $hcx-spacing;
  }

  .contractId {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
    color: var(--hcx-font-secondary);
    min-width: 0;
    max-width: 100%;
    display: block;

    @include ellipsis;
  }
}

@import 'styles/theme/theme';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $hcx-spacing 2 * $hcx-spacing;
}

.priceLine {
  background-color: transparent;
  padding: 0;
  width: 345px;
}

.buyBtn {
  padding: 3px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
}

@import 'styles/theme/theme';

.root {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding: 2 * $hcx-spacing 0 3 * $hcx-spacing 2 * $hcx-spacing;
}

.icon {
  margin-right: 1.5 * $hcx-spacing;
}

.nullStateIcon {
  width: 198px;
  height: 198px;
}

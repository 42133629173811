@import 'styles/theme/theme';

.root {
  overflow-y: scroll;
  max-height: 450px;
  padding: 2 * $hcx-spacing 2 * $hcx-spacing 0 2 * $hcx-spacing;
}

.chart {
  width: 100%;
  table-layout: auto;
  color: var(--hcx-font-primary);

  tr {
    cursor: pointer;
  }

  th {
    padding-right: 1.5 * $hcx-spacing;
  }

  .thresholdBackground {

    .defaultInfoArrow {
      background-color: var(--hcx-green);
    }

    .redInfoArrow {
      background-color: var(--hcx-red);
    }

    .redBottomBorder {
      border-bottom: 1px solid var(--hcx-red);
    }
  }

  .defaultInfoArrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    clip-path:
      polygon(
        0 0,
        calc(100% - #{1.5 * $hcx-spacing}) 0,
        100% 54%,
        calc(100% - #{1.5 * $hcx-spacing}) 100%,
        0 100%
      );
    color: var(--hcx-font-primary);
    padding: 0 2 * $hcx-spacing 0 1.5 * $hcx-spacing;
    border-radius: 5px;
  }

  td {
    width: 100%;
    padding: $hcx-spacing 0;
  }

  .bottomBorder {
    border-bottom: 1px solid var(--hcx-green);
  }

  tbody > tr:last-child > td {
    cursor: default;
  }

  .rate {
    background-color: var(--hcx-cell-prefix);
    height: 14px;
    margin-right: 2px;
  }

  .rate:last-child {
    margin-right: 0;
    border-radius: 0 5px 5px 0;
  }

  .magenta {
    background-color: var(--hcx-magenta);
  }

  .cellWrapper {
    display: flex;
  }
}

@import 'styles/theme/theme';

.root {
  background-color: var(--hcx-card);
  position: fixed;
  width: 380px;
  padding: $hcx-spacing $hcx-spacing * 2;
  border-bottom: 0.5px solid #000;
}

.row {
  display: flex;
  align-items: center;
  gap: $hcx-spacing;
}

.rootFilled {
  background-color: var(--hcx-select-background);
  border-radius: 100px;
  overflow: hidden;

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }

  &:hover {
    background-color: var(--hcx-select-background);
  }

  &:global(.Mui-focused) {
    background-color: var(--hcx-select-background);
  }
}

.filled {
  padding: 6px 12px;
  font-size: 10px;
}

.icon {
  color: white;
}

.menuPaper {
  background-color: var(--hcx-select-content-background);
  box-shadow: none;
  border-radius: 4px;
  border: none;
  margin-top: 3px;
}

.menuPaper .menuList {
  padding-top: 0;
}

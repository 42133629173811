.root {
  color: var(--hcx-font-primary);
  text-align: left;
  vertical-align: top;

  caption,
  th,
  td {
    text-align: left;
    vertical-align: top;
    padding: 0;
  }

  caption {
    padding-bottom: 5px;
  }

  td,
  th {
    padding-top: 12px;
  }

  th {
    padding-right: 12px;
    min-width: 110px;
  }

  tbody > tr {

    th {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--hcx-font-secondary);
    }

    td {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .valueDescription {
    font-size: 10px;
    line-height: 14px;
    color: var(--hcx-font-secondary);
  }
}

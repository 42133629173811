.root {
  padding: 15px 7vw 15px 30px;
  border-bottom: 1px solid var(--hcx-background);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoImage {
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;
  object-fit: cover;
}

.iconColor {
  color: var(--hcx-font-raiting);
}

.radialBadge {
  background-color: var(--hcx-radioselect-border);
  padding: 7px 13px;
  width: fit-content;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteButton {
  min-width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.colorInvitation {
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: var(--hcx-font-pending);
}

.colorIConfirmation {
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: var(--hcx-font-confirmation);
}

.flexRowBaseline {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.textWrapper {
  max-width: 645px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

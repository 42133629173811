@import 'styles/theme/theme';

.row {
  width: 100%;

  &:hover {
    background-color: var(--hcx-field);
  }
}

.row.active .cell:first-child {
  position: relative;

  &::before {
    $indicator-size: 6px;

    content: ' ';
    position: absolute;
    top: calc(50% - #{$indicator-size / 2});
    left: $hcx-spacing;
    border-radius: 50%;
    width: $indicator-size;
    height: $indicator-size;
    background-color: var(--hcx-button);
  }
}

.cell {
  vertical-align: top;
  border-bottom: 1px solid var(--hcx-table-border);
  padding: $hcx-spacing * 3 $hcx-spacing * 1.5;

  &:first-child {
    padding-left: $hcx-spacing * 4;
  }
}

.headingCell {
  border-bottom: none;
  padding: $hcx-spacing * 3 $hcx-spacing * 1.5;
  color: var(--hcx-table-heading);
  background-color: var(--hcx-card);
  z-index: inherit;

  &:first-child {
    padding-left: $hcx-spacing * 4;
  }
}

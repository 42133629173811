@import 'styles/colors';

.root {
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 22px 40px;
  width: 100%;
}

.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
}

.contentWrapper {
  padding: 20px 40px;
}

.fontSecondary {
  color: var(--hcx-font-secondary);
  margin-bottom: 30px;
}

.subtitleStile {
  margin-bottom: 10px;
}

.firstParagraph {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 45px;
}

.videoWarpper {
  min-width: 450px;
  height: 255px;
  background: url('vimeo-player.png');
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.colorBlue {
  color: var(--hcx-blue);
  cursor: pointer;
}

.buttonRow {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  justify-content: flex-end;
}

.card {
  width: 100%;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

@import 'styles/theme/theme';
@import './sponsor-application.constants';

.root {
  width: $sponsor-panel-width;
  right: 0;
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: $hcx-spacing * 2;
  box-shadow: -4px 0 14px rgba(0, 0, 0, 0.5);
}

.customDivider {
  border-bottom-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.9);
}

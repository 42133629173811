.order {
  color: var(--hcx-font-primary);
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 20px;
  }

  &:not(:first-child) {
    padding-top: 16px;
  }
}

.orderNumberWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderNumber {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.info {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #D3D3D3;
}

.open {
  color: var(--hcx-alert-up);
}

.canceled {
  color: var(--hcx-alert-down);
}

.filled {
  color: var(--hcx-button);
}

.more {
  padding: 4px;
}

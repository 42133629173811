@import 'styles/theme/theme';
@import '../sponsor-management.constants';

.description {
  font-size: 14px;
  color: var(--hcx-font-secondary);
  padding: 0 10px 20px;
}

@mixin first-child {

  &:first-child {
    padding-left: 15px;
  }
}

.headCell {
  padding: 0;

  @include first-child;
}

.noHoverPointer {
  cursor: none;
}

.textHeading {
  color: var(--hcx-font-table-header);
  font-size: 12px;
  line-height: 16px;
}

.cellWrapper {
  display: flex;
  align-items: center;
  min-width: 20px;
  cursor: pointer;
}

.tableRow {
  display: flex;
  align-items: center;
}

.tableRowBody {
  background-color: var(--hcx-accordion-background);
}

.tableRowDisabled {
  opacity: 0.3;

  &:hover {
    opacity: 0.3;
  }
}

.tableCell {
  padding: 16px 0;
  vertical-align: middle;

  @include first-child;
}

.footer {
  background-color: var(--hcx-card);
  position: fixed;
  bottom: 0;
  padding: $hcx-spacing $hcx-spacing * 4 $hcx-spacing $hcx-spacing * 2;
  width: $sponsor-panel-width;
  display: flex;
  justify-content: flex-end;
}

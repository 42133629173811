.root {
  z-index: 0;
}

.selectPhoto {

  & > input {
    display: none;
  }

  & > button {
    background-color: var(--hcx-card);
    padding: 3px;
  }
}

@import 'styles/theme/theme';

@mixin input-basics {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 11px;
  padding: 6px 15px;
  border-radius: 2px;
}

.input {
  @include input-basics;

  color: var(--hcx-font-primary);
  border: 1px solid var(--hcx-input-border);
  background: var(--hcx-input-background);

  &:disabled {
    background: var(--hcx-input-background-disabled);
    border: 1px solid var(--hcx-input-border-disabled);
    color: var(--hcx-font-secondary);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    border: 1px solid var(--hcx-input-border-hover);
  }

  &::placeholder {
    color: var(--hcx-icon-primary);
  }
}

.error {
  @include input-basics;

  background: var(--hcx-input-background-error);
  border: 1px solid var(--hcx-input-border-error);
  color: var(--hcx-input-text-error);

  &:hover {
    border: 1px solid var(--hcx-input-border-error);
  }
}

.errorText {
  position: absolute;
  font-weight: 400;
  font-size: 10px;
  color: var(--hcx-input-text-error);
}

.inputLabel {
  margin-bottom: 7px;
  display: flex;
  gap: 8px;
}

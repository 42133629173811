@import 'styles/theme/theme';

.root {
  padding: 3 * $hcx-spacing;
  display: flex;
  flex-direction: column;
  margin-bottom: $hcx-spacing;
}

.title {
  margin-bottom: 3 * $hcx-spacing;
}

.prefix {
  margin-right: $hcx-spacing;
}

.header,
.totalBids,
.bid,
.countdown {
  display: flex;
  align-items: center;
}

.header {
  justify-content: flex-start;
  margin-bottom: 2.5 * $hcx-spacing;
}

.bidPrefix {
  height: 8px;
  width: 8px;
  background-color: var(--hcx-magenta);
  margin-right: 0.5 * $hcx-spacing;
}

.bid {
  justify-content: center;
  margin-right: 3.5 * $hcx-spacing;
  width: 63px;
  height: 42px;
  background-color: var(--hcx-background);
  border-radius: 100px;
}

.countdown {
  margin-right: 5 * $hcx-spacing;
}

.auctionGridWrapper {
  margin-left: auto;
}

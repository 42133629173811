@import 'styles/theme/theme';
@import 'styles/colors';
@import '../sponsor-application.constants';

.root {
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: $hcx-spacing * 1.5;
}

.corporateWidth {
  width: 253px;
  margin-right: $hcx-spacing;
}

.corporateTypeWidth {
  width: 206px;
  text-transform: capitalize;
}

.countryWidth {
  width: 174px;
  text-transform: uppercase;
}

.aptWidth {
  width: 174px;
}

.stateWidth {
  width: 82px;
  margin-right: $hcx-spacing;
}

.originWidth {
  width: 176px;
}

.descriptionWidth {
  max-width: $sponsor-panel-width;
  width: 100%;
}

.descriptionWidth :global(.MuiInputBase-inputMultiline) {
  height: 72px !important;
}

.webWidth {
  width: 425px;
}

.phoneWidth {
  width: 180px;
  margin-right: $hcx-spacing * 1.5;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}

.row {
  margin-top: 17px;
  display: flex;
}

.companyLogo {
  margin-top: $hcx-spacing;
}

.description {
  color: var(--hcx-font-secondary);
  margin-top: 3px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $hcx-spacing * 3;
  min-width: 120px;
}

.headerDescription {
  color: var(--hcx-font-primary);
  margin-bottom: $hcx-spacing;
}

.link {
  font-size: 12px;
  margin-top: $hcx-spacing;
}

.root {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 17px 27px;
  background-color: var(--hcx-field);

  & > div {
    flex: 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
@import 'colors';
@import 'theme/theme';

* {
  box-sizing: border-box;
  scrollbar-width: 4px;
  scrollbar-color: #0C0C18 #0C0C18;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: var(--hcx-background);
  font-family: $hcx-main-font, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #323D54;
  border: none;
  border-radius: 60px;
}

body::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 11px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body.overflow-hidden {
  overflow: hidden;
}

@import 'styles/theme/theme';

@mixin cell-spacing {
  padding: 0;

  &:not(:first-child) {
    padding-left: $hcx-spacing;
  }

  &:not(:last-child) {
    padding-right: $hcx-spacing;
  }
}

.headCell {
  @include cell-spacing;

  padding-bottom: $hcx-spacing * 0.5;
  text-transform: capitalize;

  &:first-child {
    width: 155px;
    padding-left: 0;
  }
}

.cell {
  padding: 0 $hcx-spacing;
  border: 0;

  &:first-child {
    padding-left: 0;
    width: 155px;
  }
}

.bodyRow {

  &:hover {
    background-color: transparent;
  }
}

.caption {
  font-size: 11px;
  margin-top: 5px;
}

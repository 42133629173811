@import 'src/styles/theme/theme';
@import 'src/styles/theme/utils';

.root {
  display: flex;
}

.photoWrapper {
  position: relative;
  margin-right: $hcx-spacing;
  width: 175px;
  min-width: 175px;

  .photo {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-right: $hcx-spacing;
  }

  .rating {
    position: absolute;
    right: 2px;
    top: 37px;
  }
}

.nameWrapper {
  align-self: center;
  margin-right: 8 * $hcx-spacing;
}

.name {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;

  @include ellipsis;
}

.contractId {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 12px;
  background: #243F7A;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: fit-content;
  margin: 4px 0 10px;
}

.priceLineWrapper {
  background-color: transparent;
  width: 375px;
}

.rightSection {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 2 * $hcx-spacing;
}

.controlsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chartButtonsWrapper {
  display: flex;
}

@import 'styles/theme/theme';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--hcx-account-profile-header);
  width: 100%;
  height: 58px;
  padding: 0 $hcx-spacing * 2;
  padding-right: 12px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: var(--hcx-color-primary);
}

.menuList {
  margin-left: -20px;

  ul {
    padding: 0 !important;
  }
}

.menuItem {
  padding: 10px 40px 10px 15px;
  background-color: #293244;

  &:hover {
    background-color: #212937;
  }
}

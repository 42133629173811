.TVChartContainerWrapper {
  padding: 5px;

  & > div {
    position: relative;
    box-sizing: content-box;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.TVChartContainer {
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% + 38px);

  &.noHeader {
    top: 0;
    height: 100%;
  }
}

.headingLabel {
  margin-bottom: 26px;
  font-size: 12px;
  color: var(--hcx-font-secondary);
}

.selectType {
  width: 246px;
  margin-bottom: 18px;
}

.inputField {
  width: 95%;
  margin-bottom: 11px;

  &:last-child {
    width: 100%;
  }
}

.asterics {
  color: var(--hcx-font-primary);
}

.submit {
  margin: 16px 0 0 20px;
  align-self: flex-end;
}

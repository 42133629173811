@import 'styles/theme/theme';

@mixin cell-spacing {
  padding: 0;

  &:not(:first-child) {
    padding-left: $hcx-spacing;
  }

  &:not(:last-child) {
    padding-right: $hcx-spacing;
  }
}

.scrollbar {
  flex: 1;
}

.root {
  padding: 0;
}

.headCell {
  @include cell-spacing;

  padding-bottom: $hcx-spacing * 0.5;
  text-transform: capitalize;

  &:first-child {
    width: 155px;
  }
}

.cell {
  @include cell-spacing;

  padding-top: $hcx-spacing;
  padding-bottom: $hcx-spacing;
  border: 0;

  &:first-child {
    width: 155px;
    padding-left: 0;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.bodyRow {

  &:last-child > .cell {
    padding-bottom: 0;
  }

  &:hover {
    background-color: transparent;
  }
}

.caption {
  font-size: 11px;
  margin-top: 5px;
}

.headshotPlaceholder {
  margin-right: $hcx-spacing;
}

@import 'src/styles/theme/theme';

$content-height: calc(100vh - #{$header-height + $hcx-spacing * 2});

.chartContainer {
  display: flex;
  flex-direction: column;
}

.customPanel {
  @extend .chartContainer;

  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chart {
  flex: 1;
}

.resizable {
  grid-area: resizable;
  flex: 1;
}

.card {
  grid-area: hcxcard;
  margin-left: $hcx-spacing;
  width: $athlete-card-width;
  min-width: $athlete-card-width;

  :global(#hcx-card) {
    height: 100%;
    margin-left: 0;
  }
}

.bottomRow {
  min-height: 140px;

  & > div:first-child {
    margin-right: $hcx-spacing;
  }
}

.chartRow {
  min-height: 240px;
}

.container {
  min-height: $content-height;
  width: calc(100vw - #{$hcx-spacing * 2});
  display: flex;
  flex-flow: row nowrap;
}

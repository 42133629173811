@import 'styles/theme/theme';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion {
  background: transparent;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid var(--hcx-divider);
  background-color: var(--hcx-accordion-background);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.expanded {
  background: var(--hcx-accordion-background);
  margin: 0 !important;
  margin-bottom: 2px !important;
}

.accordionDisabled {
  display: none;
}

.summary {
  padding: 0;
  padding: 0 $hcx-spacing;
}

.summaryContent {
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}

.summaryIcon {
  background: transparent;
  margin-right: 4px;
}

.summaryDisabled {
  opacity: 1 !important;
}

.details {
  padding: 0;
}

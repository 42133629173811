@import 'styles/theme/theme';

.root {
  margin: $hcx-spacing 0;
  display: flex;
  align-items: center;
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #FFF;
  margin-right: $hcx-spacing;
}

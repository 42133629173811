@import 'styles/theme/theme';

.root {
  max-width: 49px;
  margin-right: $hcx-spacing * 2;
}

.label {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: var(--hcx-font-secondary);
  margin-left: 7px;
  margin-top: 3px;
  user-select: none;
}

.input {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}

.inputArrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 3px;

  & > svg {
    cursor: pointer;
    color: var(--hcx-icon-default);

    &:hover {
      color: var(--hcx-font-primary);

      path {
        fill: var(--hcx-font-primary);
      }
    }

    &:first-child {
      margin-bottom: 3px;
    }
  }
}

@import 'src/styles/theme/theme';

.content {
  flex-basis: 100%;
}

.topLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--hcx-divider);
  min-height: 80px;
  padding-inline: $hcx-spacing;
}

.tab {
  font-size: 14px;
  padding: 15px 35px;
}

.indicatorSpan {
  min-width: 100px;
}

.tabs {
  align-self: flex-end;
}

.addWallet {
  margin-right: $hcx-spacing * 4;
}

@import 'styles/theme/theme';
@import 'styles/theme/utils';
@import '../account-profile.shared';

.linkWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}

.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--hcx-font-primary);
  opacity: 0.9;
  display: block;
  margin-right: $hcx-spacing;

  @include ellipsis;
}

.copyBtn {
  position: relative;
  width: 24px;
  height: 24px;

  svg {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 5px;
    left: 5px;
  }

  .copyIcon {
    opacity: 1;
    transition: all 300ms ease-out;
    color: var(--hcx-blue);
  }

  .doneIcon {
    opacity: 0;
    transition: all 300ms ease-in-out;
    color: var(--hcx-alert-up-alt);
  }

  &.copied {

    .copyIcon {
      opacity: 0;
    }

    .doneIcon {
      opacity: 1;
    }
  }
}

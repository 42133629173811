@import 'styles/theme/theme';
@import '../../column-configuration/constants';

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $hcx-spacing * 3 $hcx-spacing * 4 0 $hcx-spacing * 3;
}

.headingFlexWrapper {
  display: flex;
}

.headingTitle {
  margin-left: $hcx-spacing;
}

.active {
  background-color: #026700;
  color: white;
}

.scheduled {
  background-color: #8300C0;
  color: white;
}

.closed {
  background-color: #8E95A5;
  color: black;
}

.chip {
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 11px;
  text-transform: uppercase;
  padding: 3px 0;
  height: auto;
}

.root {
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
}

.panelWrapper {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.panelWrapperShort {
  max-width: calc(100% - #{$athlete-card-width + $hcx-spacing});
}

.mintWrapper {
  width: 100%;
}

.mintWrapperViewConfigOpen {
  max-width: calc(100% - #{$columns-config-width + $hcx-spacing});
}

.table {
  overflow-x: auto;
  max-width: 100%;
}

.row {
  cursor: pointer;
}

.config {
  margin-left: $hcx-spacing;
}

.bidBtn {
  padding: 4px 3 * $hcx-spacing;
}

.selectedRow {
  background-color: var(--hcx-field);
}

.prefixDot {
  width: 6px;
  height: 6px;
  background-color: var(--hcx-blue);
  position: relative;
  border-radius: 50%;
  right: 2 * $hcx-spacing;
}

.userAvatar {
  position: inherit;
}

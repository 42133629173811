.root {
  padding: 15px;
  padding-bottom: 9px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}

.switcher {
  margin-right: auto;
}

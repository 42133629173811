@import 'src/styles/theme/theme';

.root {
  display: flex;
  flex-direction: row;
}

.column {
  width: $athlete-card-width;
  margin-left: $hcx-spacing;

  & > div:last-child {
    flex: 1;
  }
}

.orders,
.favorites,
.snapshot,
.trending {
  min-height: 150px;
}

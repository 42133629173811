.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 40px;
}

.cancelButton {
  padding: 3px 19px;
  background-color: var(--hcx-button-cancel);
  border: 1px solid var(--hcx-button-border-cancel);
  color: var(--hcx-font-primary);
  margin: 0 16px;

  &:hover {
    background-color: var(--hcx-button-cancel) !important;
    color: var(--hcx-font-primary) !important;
  }
}

@import 'styles/theme/theme';

.root {
  display: flex;
  align-items: center;
  height: 30px;
}

.label {
  margin-right: $hcx-spacing;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
}

@import 'styles/theme/theme';

.root {
  padding: $hcx-spacing;
  display: flex;
  flex-direction: column;
  margin-bottom: $hcx-spacing;
}

.header {
  display: flex;
  width: 100%;
  min-width: 0;
  padding: $hcx-spacing 0.8 * $hcx-spacing $hcx-spacing 1.6 * $hcx-spacing;
}

.headerInfo {
  flex: 1;
  overflow-x: auto;
}

.headerInfoTable {
  text-align: start;
  vertical-align: bottom;
  width: 100%;

  th {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: var(--hcx-font-secondary);
  }

  td {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--hcx-font-primary);

    .green {
      color: var(--hcx-alert-up-alt);
    }

    .red {
      color: var(--hcx-alert-down);
    }

    .percentage {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      margin-left: $hcx-spacing;
    }
  }

  th,
  td {
    vertical-align: bottom;
    text-align: left;
    padding-left: $hcx-spacing / 2;
    white-space: nowrap;

    & > svg {
      margin: 0 auto;
    }

    &:first-child {
      width: 18px;
      padding-left: 0;
    }
  }
}

.headerActions {
  margin-left: $hcx-spacing;
  display: flex;
  align-items: center;
}

.chart {
  padding: 0;
  flex: 1;
}

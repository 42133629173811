@import 'src/styles/theme/theme';

.selectRoot {

  &::before,
  &::after,
  &:hover::before,
  &:hover::after {
    content: none;
  }

  color: var(--hcx-font-secondary);
  background: var(--hcx-field);
  font-family: $hcx-main-font;
  padding: 0;
  border-radius: 100px;
  height: 58px;
  min-width: 145px;

  &:global(.Mui-focused) {
    background: var(--hcx-field);
  }
}

.selectSelect {
  padding: 0 22px;

  &:focus {
    background: transparent;
  }
}

.selectIcon {
  color: var(--hcx-icon-default);
}

.selectPaperRoot {
  margin-top: 18px;
  box-shadow: none;
  border: none;
  border-radius: 29px;
}

.selectPaperRoot .selectMenuList {
  padding: $hcx-spacing 0;
}

.selectMenuItem {
  height: 43px;
  display: flex;
  align-items: center;
}

.phoneWrapper {
  position: relative;
  width: 100%;
  margin-left: $hcx-spacing;
}

.phoneInput {
  width: 100%;
}

.sendCode {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

@import 'src/styles/theme/theme';
@import 'src/styles/theme/utils';

.container {
  width: 100vw;
  height: calc(100vh - #{$hcx-spacing * 2});
  color: var(--hcx-font-secondary);
  box-sizing: border-box;

  @include flex-center;
}

.card {
  min-width: 420px;
  padding: 56px 63px 30px;
  background: var(--hcx-card);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 156px;
}

.inputRoot {
  width: 294px;
  height: 58px;
}

.input {
  color: var(--hcx-font-secondary);
  background: var(--hcx-field);
  font-family: $hcx-main-font;
  padding: 0 22px;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  border-radius: 100px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

@mixin text-sm {
  font-size: 10px;
  line-height: 14px;
}

.link {
  color: var(--hcx-link);
  text-decoration: none;
}

.terms {
  @include text-sm;
}

.textLine {
  text-align: center;
  margin-top: $hcx-spacing * 2;

  @include text-sm;
}

.googleButton {
  background: #FFF !important;
}

.googleLogo {
  width: 15px;
  height: 15px;
}

.mt2 {
  margin-top: $hcx-spacing * 2;
}

.mt4 {
  margin-top: $hcx-spacing * 4;
}

.loading {
  height: 20px !important;
  width: 20px !important;
}

.form {
  display: flex;
  flex-direction: column;
}

.error {
  font-size: 10px;
  padding: 0 22px;
  color: var(--hcx-alert-down);
  margin-top: 4px;
}

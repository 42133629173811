.description {
  padding: 15px 15px 30px;
  font-size: 14px;
  color: var(--hcx-font-secondary);
}

.button {
  padding: 2px 18px;
  margin: 20px 15px 28px 0;
}

.inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.widthIncome {
  width: 151px;
  margin-right: 10px;
}

.widthPersent {
  width: 67px;
  margin-right: 10px;
}

.widthNumber {
  width: 117px;
  margin-right: 10px;
}

.infoDescription {
  font-size: 14px;
  color: var(--hcx-font-secondary);
  margin-right: 10px;
}

.infoIcon {
  width: 14px;
  height: 14px;
  color: var(--hcx-icon-primary);
}

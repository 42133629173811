@import 'styles/theme/theme';

.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: $hcx-spacing * 2;
}

.actions {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 6px;
}

.link {
  margin-top: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.athleteCard {
  padding-top: 0;
}

.small {
  padding-bottom: 10px;
  margin-top: -24px;

  .actions {
    padding-bottom: 0;
  }

  :global(.AthleteCardNameWrapper) {
    padding-right: 90px;
  }
}

@import 'src/styles/theme/theme';

.select {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
}

.selectRoot {
  border: none;

  :global(.MuiOutlinedInput-notchedOutline) {
    display: none;
  }
}

.selectIcon {
  color: var(--hcx-font-secondary);
}

.selectPaper {
  background: var(--hcx-field);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  border: 0;
}

.selectPaper .selectList {
  padding-top: 0;
}

.selectMenuItem {
  padding: 13px 15px;

  &:hover {
    background: var(--hcx-card);
  }
}

.flag {
  margin-right: $hcx-spacing;
}

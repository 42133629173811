$wrapper-padding-top: 16px;
$wrapper-padding-right: 16px;
$wrapper-padding-bottom: 23px;
$wrapper-padding-left: 16px;

.root {
  border: 1px solid var(--hcx-button);
  box-sizing: border-box;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.55);
  border-radius: 6px;
  margin: 43px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: $wrapper-padding-top 47px $wrapper-padding-bottom $wrapper-padding-left;
}

.close {
  position: absolute;
  top: 7px;
  right: 7px;
}

.content {
  padding: 0 16px $wrapper-padding-bottom;
}

.actions {
  padding:
    calc(28px - #{$wrapper-padding-bottom}) $wrapper-padding-right $wrapper-padding-bottom
    $wrapper-padding-left;
}

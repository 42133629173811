@import 'styles/theme/theme';

.root {
  margin: $hcx-spacing 0;
  background: #BD7100;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 40px;
}

.info {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #FFF;
}

.action {
  box-shadow: none !important;
  background-color: white;
  color: #885A00;
  border-color: #885A00;
  padding: 4px 21px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;

  &:hover {
    background-color: white;
    border-color: #885A00;
  }
}

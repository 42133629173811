@import 'styles/theme/theme';
@import 'styles/colors';
@import '../sponsor-management.constants';

.rootContainer {
  position: fixed;
  right: $sponsor-panel-width;
  /* stylelint-disable -- stylelint fix Min to min so comparing not working */
  max-width: Min($activity-panel-width, calc(100% - #{$sponsor-panel-width}));
}

.root {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  width: $activity-panel-width;
  flex-direction: column;
}

.sectionWrapper {
  padding: $hcx-spacing * 1.5;
}

.sectionRoot {
  padding: $hcx-spacing * 1.5;
  background-color: var(--hcx-input-background);
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $hcx-spacing;
}

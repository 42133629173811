.contractId {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
  margin-top: 35px;
}

.contractSelect {
  margin-top: 20px;
}

.formWrapper {
  width: 100%;
  margin-top: 15px;
}

.formRow {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.formItem {
  margin-top: 30px;
}

.switchersBlock {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: -12px;
}

.submitBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.amount {
  flex-direction: row-reverse;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.infoIcon {
  transform: translateY(-2px) scale(0.7);

  path {
    fill: var(--hcx-icon-primary);
  }
}

.athletePriceLine {
  background-color: transparent;
  padding: 0;
}

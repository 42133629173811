.infoTable {
  padding-top: 20px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 15px;
  }
}

.highlightsTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-primary);
  margin-bottom: 16px;
}

.pointer {
  cursor: pointer;
}

@import 'styles/theme/theme';
@import 'styles/colors';
@import '../account-profile.shared';

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $hcx-spacing * 3.5 $hcx-spacing * 4 $hcx-spacing * 3 $hcx-spacing * 5;
}

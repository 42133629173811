@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  flex: 1;
}

.tabs {
  padding: 1.5 * $hcx-spacing 0 0 2 * $hcx-spacing;
}

.tabPanel {
  padding-bottom: 2 * $hcx-spacing;
}

.flexWrapper {
  @include flex-center;

  justify-content: space-between;
}

.rightSection {
  display: flex;
  align-items: center;
}

.personalBidWrapper {
  display: flex;
  align-items: center;
  margin-right: 3 * $hcx-spacing;
}

.personalBidContainer {
  min-width: 190px;
  max-height: 31px;
  background-color: var(--hcx-background);
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  padding-left: 1.5 * $hcx-spacing;
}

.redPersonalBidContainer {
  background-color: var(--hcx-dark-red);
  border: 1px solid var(--hcx-red);
}

.personalBidPrefix {
  height: 8px;
  width: 8px;
  background-color: var(--hcx-magenta);
  margin-right: 0.5 * $hcx-spacing;
}

.cellName {
  margin-right: $hcx-spacing;
}

.redCellName {
  color: var(--hcx-red);
}

.button {
  margin-right: 3 * $hcx-spacing;
}

.deleteButton {
  margin-left: 1.5 * $hcx-spacing;
}

.linkButton {
  text-decoration: none;
}

.tokenClaimText {
  margin-right: 3 * $hcx-spacing;
}

.openBids {
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-right: 2 * $hcx-spacing;
}

@media (max-width: 1400px) {

  .flexWrapper {
    flex-direction: column;
  }

  .personalBidWrapper {
    margin-top: 2 * $hcx-spacing;
    margin-bottom: 2 * $hcx-spacing;
  }
}

@media (max-width: 1000px) {

  .rightSection {
    flex-direction: column;
    margin-bottom: 2 * $hcx-spacing;
  }
}

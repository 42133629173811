@import 'styles/theme/theme';

.root {
  max-width: 295px;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: var(--hcx-card);
  box-shadow: 0 0 22px #000;

  [class*='MuiDialogTitle-root'] {
    display: none;
  }
}

.backdrop {
  backdrop-filter: blur(4px);
}

.contentWrapper {
  padding: $hcx-spacing;
  display: flex;
  flex-direction: column;
}

.textSecondary {
  color: var(--hcx-font-secondary);
  margin-top: $hcx-spacing;
}

.goPublicBtn {
  margin: $hcx-spacing * 2 auto 0;
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  width: 178px;
}

.actions {
  margin-top: -$hcx-spacing;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--hcx-font-secondary) !important;
  }
}

@import 'styles/colors';
@import 'features/account-profile/account-profile.constants';

.root {
  position: absolute;
  left: 50%;
  bottom: 60px;
  display: flex;
  justify-content: center;
  width: calc(100vw - #{$account-profile-width});
}

.errorContainer {
  background: var(--hcx-input-background);
  border-radius: 2px;
  max-width: max-content;
  position: relative;
  left: -50%;
  align-items: center;
  padding: 0 15px;
}

.iconWrapper {
  margin: 0;
  padding: 0;
}

.action {
  padding-top: 0;
}

.message {
  padding-left: 15px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}

.outlinedError {
  color: var(--hcx-input-text-error);
  border-color: var(--hcx-input-border-error);
}

.outlinedSuccess {
  color: var(--hcx-alert-up-alt);
  border-color: var(--hcx-green);
}

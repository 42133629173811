@import 'styles/theme/theme';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $hcx-spacing * 2 0;
  overflow: hidden;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 $hcx-spacing * 1.2 $hcx-spacing * 1.8 $hcx-spacing * 1.5;
}

.additionalHeaderInfo {
  padding: 0 $hcx-spacing * 1.5;
}

.headerIcon {
  margin-right: $hcx-spacing;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
  }
}

.title {
  text-transform: capitalize;
}

.headerActions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.content {
  flex: 1;
  width: 100%;
  padding: 0 $hcx-spacing * 1.5;
}

.snackbar {
  border: none;
  border-radius: 10px;
  background: white;
  color: var(--hcx-background);
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}

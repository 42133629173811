@import 'styles/theme/theme';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: 1fr $athlete-card-width;
  grid-template-rows: 1fr;
  flex: 1;
  gap: $hcx-spacing;
}

.sideBlock {

  & > div:not(:last-child) {
    margin-bottom: $hcx-spacing;
  }
}

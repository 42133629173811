@import 'styles/theme/theme';

.root {
  background-color: var(--hcx-card);
  width: $athlete-card-width;
  min-width: $athlete-card-width;
  max-width: $athlete-card-width;
  min-height: calc(100% - 122px);
  margin-left: $hcx-spacing;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabView {
  display: flex;
  flex: 1;
}

.tabPanelWrapper {
  flex: 1;
}

.tabPanel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.tabViewSpacingX {
  width: 100%;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  padding: 0 15px;
}

.tabViewSpacingY {
  margin: 15px 0;
}

.rotationContainer {
  position: relative;
  min-width: $athlete-card-width;
  height: 175px;

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    transition: 0.5s;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
  }

  .back {
    transform: rotateY(180deg);
    background-color: var(--hcx-card);

    .athleteCard {
      padding: 0 $hcx-spacing * 2;
      padding-bottom: 9px;
    }

    .chart {
      flex: 1;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }

  &.rotated {
    height: 300px;

    .back {
      transform: rotateY(0deg);

      .chart {
        visibility: visible;
        opacity: 1;
      }
    }

    .front {
      transform: rotateY(-180deg);
    }
  }
}

.role {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
  margin-right: auto;
  padding: $hcx-spacing * 1.4 $hcx-spacing * 2;
}

.priceLine {
  background-color: transparent;
}

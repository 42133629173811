@import 'styles/theme/theme';

.tooltip {
  border-radius: 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-primary);
  padding: $hcx-spacing;
  max-width: 200px;
}

.tooltip,
.arrow::before {
  background: var(--hcx-background);
  box-shadow: 0 3px 8px var(--hcx-tooltip-shadow);
  border: 1px solid var(--hcx-tooltip-border);
}

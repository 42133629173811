@import 'styles/theme/theme';

.root {
  display: flex;
  justify-content: center;
  color: var(--hcx-font-primary);
  padding: 5px $hcx-spacing;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--hcx-panel);
  box-shadow: 0 -1px 0 #232938, 0 -6px 8px rgba(0, 0, 0, 0.65);
  height: $ticker-bar-height;
  z-index: 100;
}

.tickers {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
}

.symbolContainer {
  display: flex;
  align-items: center;
  padding: 0 $hcx-spacing;

  &:not(:last-child) {

    &::after {
      content: '';
      border-right: 1px solid var(--hcx-icon-primary);
      height: $hcx-spacing * 2;
      margin-left: calc(#{$hcx-spacing} * 2);
    }
  }
}

@mixin item-spacing {
  margin-left: $hcx-spacing;

  &:first-child {
    margin-left: 0;
  }
}

.item {
  padding: 7px $hcx-spacing 3px;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 2px;

  @include item-spacing;
}

.athleteItemWrapper {
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: var(--hcx-field);
  }

  @include item-spacing;
}

.icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

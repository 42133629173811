@import 'styles/theme/theme';

.accordion {
  box-shadow: none;
  border: none;
  background: transparent;
}

.summary {
  flex-direction: row;
  padding: 0;
  align-items: center;

  .summaryContent {
    align-items: center;
    margin: 0;
  }
}

.details {
  padding: 0 $hcx-spacing * 0.9 $hcx-spacing * 2 $hcx-spacing * 2.3;
}

.iconColor {
  color: var(--hcx-icon-default);
  fill: var(--hcx-icon-default);
}

.socialIcon {
  margin-right: $hcx-spacing;
  display: flex;
}

.switcher {
  margin-left: auto;
}

.checkboxes {
  margin-bottom: $hcx-spacing * 2;
}

.delayTitle {
  color: var(--hcx-font-primary);
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
}

.delayBlock {
  display: flex;
  align-items: center;
}

.saveBtn {
  margin-left: auto;
  margin-top: 5px;
}

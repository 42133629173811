@import 'styles/theme/theme';
@import 'styles/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row {
  display: flex;
  align-items: baseline;
  margin-bottom: $hcx-spacing * 1.5;
}

.date {
  margin-left: $hcx-spacing;
}

.block {
  margin-left: $hcx-spacing * 2;
}

.badge {
  background-color: var(--hcx-pending-badge);
  padding: 4px 15px;
  margin: 0 $hcx-spacing;
  font-weight: 700;
  font-size: 8px;
  line-height: 9px;
  width: fit-content;
  border-radius: 100px;
  text-transform: uppercase;
}

.rowTwo {
  display: flex;
  align-items: center;
  margin-top: $hcx-spacing * 1.5;
}

.link {
  color: var(--hcx-blue);
  cursor: pointer;
}

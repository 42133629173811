@import 'styles/theme/theme';

.container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.scrollWrapper {
  flex: 1;
  display: flex;
}

.tabViewWrapper {
  padding: 0 $hcx-spacing * 1.5;
  width: 100%;
  flex: 1;
}

.backAlert {
  border-radius: 5px;
  padding: 13px 15px;
  background: rgba(203, 110, 0, 0.15);
  display: flex;
  gap: 10px;
}

.backInfo {
  border-radius: 5px;
  padding: 13px 15px;
  background: rgba(0, 130, 203, 0.15);
  display: flex;
  gap: 10px;
}

.textAlert {
  color: var(--hcx-font-status);
}

.textInfo {
  color: var(--hcx-font-prymary);
}

@import 'src/styles/theme/theme';

.accordion {
  box-shadow: none;
  border: none;
  background: #292F40;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.summary {
  flex-direction: row-reverse;
}

.expandIconWrapper {
  color: var(--hcx-icon-default);
}

@import '../../../styles/theme/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $hcx-spacing $hcx-spacing $hcx-spacing 0;
}

.leftSection {
  display: flex;
  align-items: center;
}

.menuButtonWrapper {
  color: var(--hcx-icon-primary);
  margin: 0 6px;

  &:hover,
  &_active {
    background-color: var(--hcx-resizer);
  }
}

.menuItem {
  width: 100%;

  &:hover {
    background-color: var(--hcx-card);
  }
}

.borderTop {
  border-top: 1px solid var(--hcx-divider);
}

.logo {
  cursor: pointer;
  margin-left: 0;

  & > svg {
    width: 110px;
  }
}

.languagePicker {
  margin-right: $hcx-spacing * 2;
}

.avatar {
  margin-left: $hcx-spacing * 2;
}

.headerLogo {
  height: 33px;
  width: 77px;
}

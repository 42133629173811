@import 'styles/theme/theme';

.root {

  & > button {
    margin-left: $hcx-spacing;
    border: 1px solid #293142;
    padding: 6px;
  }
}

.enabled {
  color: white;
  background-color: #293142;
}

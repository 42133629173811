.root {
  max-width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.sortDirection {
  width: 116px;
  margin: 0 8px;
}

.iconUp {
  transform: rotate(0);
}

.iconDown {
  transform: rotate(180deg);
}

@import 'src/styles/theme/utils';
@import 'src/styles/theme/theme';

.container {
  width: calc(100vw - #{$hcx-spacing * 2});
  height: calc(100vh - #{$hcx-spacing * 2 + $header-height});
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.panel {
  margin-top: $hcx-spacing * 6;
  padding: $hcx-spacing * 2;
}

@import 'styles/theme/theme';

.root {
  padding: 3 * $hcx-spacing;
  display: flex;
  flex-direction: column;
  margin-bottom: $hcx-spacing;
}

.title {
  margin-bottom: 3 * $hcx-spacing;
}

.divider {
  margin-bottom: 2 * $hcx-spacing;
}

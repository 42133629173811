@import 'src/styles/theme/theme';

.wrapper {
  width: 100%;
  height: 100%;
  padding: $hcx-spacing 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
}

@import 'styles/theme/theme';
@import 'styles/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.elementFirst {
  width: 180px;
  margin-right: 15px;
}

.elementLast {
  width: 242px;
}

.elementStreet {
  width: 253px;
  margin-right: 10px;
}

.elementApt {
  width: 174px;
}

.elementCity {
  width: 253px;
  margin-right: 10px;
}

.elementState {
  width: 82px;
  margin-right: 10px;
}

.inputLabel {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
  margin-bottom: 7px;
}

@import 'styles/theme/theme';

.root {
  display: flex;
  align-items: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.title {
  color: var(--hcx-font-primary);
  margin-right: 2 * $hcx-spacing;
}

.gridField {
  display: flex;
  align-items: center;
}

.prefix {
  margin-right: 0.5 * $hcx-spacing;
}

@import 'styles/theme/theme';

.topSection {
  display: flex;
  margin-bottom: 2 * $hcx-spacing;
}

.setFlex {
  display: flex;
  height: 100%;
}

.stretchFlex {
  flex: 1;
  width: calc(100% - 400px);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: $hcx-spacing;

  & * + * {
    margin-left: $hcx-spacing;
  }

  button {
    padding: 3px 12px;
  }
}

@import 'styles/theme/theme';

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-secondary);
  max-width: 70%;
  display: block;
}

.actions {
  margin-top: $hcx-spacing * 2;
}

.actionBtn {
  padding: 3px 11px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
}

@import 'styles/theme/theme';

.indicatorSpan {
  max-width: 40px;
  width: 100%;
  background-color: var(--hcx-link);
}

.tabsBase {
  position: relative;
  top: 2px;
  min-height: 0;
  min-width: 0;
}

.tabsBase :global(.MuiTabs-indicator) {
  background-color: transparent;
}

.tabBase {
  text-transform: none;
  font-size: 11px;
  padding: $hcx-spacing 8px;
  min-height: 0;
  min-width: 0;
  color: var(--hcx-font-secondary);
}

.iconGray {
  color: var(--hcx-icon-primary);
}

.iconChecked {
  color: var(--hcx-icon-checked);
}

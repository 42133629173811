@import '../../../styles/theme/theme';

.wrapper {
  background-color: var(--hcx-field);
  padding: $hcx-spacing * 2 0;
}

.topLine {
  padding: 0 $hcx-spacing * 2 0 $hcx-spacing;
  display: flex;
}

.columnIcon {
  fill: var(--hcx-icon-default);
}

.titleInput {
  margin-left: $hcx-spacing / 2;
  flex: 1;

  :global(.MuiInput-input) {
    font-size: 14px;
    font-weight: 600;
  }

  :global(.MuiInput-root) {
    margin-top: 0 !important;
  }
}

.table {
  margin-top: $hcx-spacing * 2;
}

.headerRow {
  display: flex;
  flex-direction: row;
  margin-bottom: $hcx-spacing / 2;

  :nth-child(1) {
    flex: 1;
    min-width: 140px;
  }

  :nth-child(2) {
    width: 40px;
  }

  :nth-child(3) {
    flex: 2;
  }
}

.heading {
  color: var(--hcx-font-secondary);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 20px 6px 0;

  &.rowDragging,
  &:hover {
    background-color: #181E29;

    .dragIcon {
      opacity: 1;
    }
  }
}

.rowLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 140px;
}

.titleField {
  flex: 1;
}

.descriptionInput {
  flex: 2;
  margin-left: $hcx-spacing;

  :global(.MuiInput-root) {
    margin-top: 0 !important;
  }

  :global(.MuiInput-input) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

.dragHandle {
  padding: 9px 4px 9px 7px;

  &:hover {

    .dragIcon {
      fill: #FFF;
    }
  }
}

.dragIcon {
  transition: all 0.2s;
  opacity: 0;
  fill: var(--hcx-icon-default);
}

.sort {
  transition: transform 0.2s;
}

.desc {
  transform: rotate(180deg);
}

.bottomLine {
  display: flex;
  justify-content: space-between;
  margin-top: $hcx-spacing * 4;
  padding-right: $hcx-spacing * 2;
  padding-left: $hcx-spacing;
  align-items: center;
}

.deleteButton {
  visibility: hidden;
}

.deleteButtonVisible {
  visibility: visible;
}

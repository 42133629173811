@import 'styles/theme/theme';

.refreshRow {
  display: flex;
  justify-content: flex-start;

  :global(.MuiInputBase-root) {
    margin: 0 8px;
  }
}

.spacer {
  flex: inherit;
}

.toolbar {
  justify-content: center;
}

.padding {
  padding: 0;
}

.selectRoot {
  margin-right: 0 !important;
}

.select {
  font-size: 12px;
}

.selectIcon {
  color: var(--hcx-icon-primary);
}

.actions {
  color: var(--hcx-icon-primary);
  margin-left: 0 !important;
}

.refreshIcon {
  width: fit-content;
  height: fit-content;
}

@import '../../../styles/theme/theme';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: $menu-width;
  overflow: hidden;
  transition: width 500ms;

  &_open {
    width: $menu-open-width;
  }

  &_ticker_bar_opened {
    height: calc(100% - #{$ticker-bar-height});
  }
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.menuItem {
  flex: 1;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  width: 210px;
  padding: 0;

  &_expanded {

    &:hover {
      background-color: $hcx-background-color-hover;
    }
  }
}

.iconHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 36px;
  height: 36px;

  &:hover {
    background-color: $hcx-background-color-hover;
  }
}

.menuTitle {
  color: inherit;
}

.actions {

  .actionItem {
    display: flex;
    width: 100%;
    height: 46px;
    align-items: center;
    text-decoration: none;
    color: inherit;

    &_active {
      color: $hcx-color-blue;

      & .menuItem {
        color: $hcx-color-blue;
      }
    }
  }
}

@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 300px;
  aspect-ratio: 1;
  border: 20px solid #1B202D;
  border-radius: 50%;

  @include flex-center;
}

.description {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--hcx-font-primary);
  margin: 2.5 * $hcx-spacing 0;
}

.actionBtn {
  padding: 4px 15px;
}

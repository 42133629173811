@import 'styles/colors';

.root {
  padding: 0 10px 20px;
}

.wrapper {
  background: var(--hcx-accordion-background);
  padding: 15px 15px 0;

  &:last-child {
    padding-bottom: 15px;
  }
}

.border {
  position: relative;
  border: 1px solid var(--hcx-radioselect-border);
  border-radius: 4px;
  min-width: 100%;
}

.rowHeading {
  display: flex;
  height: 35px;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 19px 10px 15px;
}

.description {
  color: var(--hcx-font-secondary);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
}

.radio {
  margin: 0;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;
    color: var(--hcx-blue);
  }
}

.radioLabel {
  margin-left: 15px;
}

.textWrapper {
  padding: 0 19px 9px 48px;
}

.badgeBackground {
  top: 13px;
  right: 50%;
}

.badgeIcon {
  margin-top: -6px;
  width: 150px;
  height: auto;
  color: var(--hcx-radioselect-border);
}

.iconWrapper {
  position: relative;
}

.checkedIcon {
  color: var(--hcx-blue);
}

.checkedText {
  color: #000 !important;
}

.badgeText {
  position: absolute;
  left: 56px;
  top: 1px;
  font-size: 10px;
  font-weight: 500;
  color: var(--hcx-font-primary);
}

@import 'styles/theme/theme';

.root {
  padding: $hcx-spacing * 2 $hcx-spacing * 1.5;
  height: 350px;
  max-height: 350px;
}

.header {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: $hcx-spacing * 1.2;

  svg {
    margin-right: $hcx-spacing / 2;
  }
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-secondary);
  margin-bottom: $hcx-spacing * 2;
}

@import '../../styles/theme/utils';

.root {
  font-style: normal;
  text-transform: none;
  border-radius: 100px;

  @include default-font;
}

.sizeMedium {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 12px;
}

.sizeSmall {
  padding: 4px 15px;
  font-weight: 700;
  font-size: 12px;
}

.containedPrimary {
  background: var(--hcx-button);
  background-color: var(--hcx-button);
  color: var(--hcx-button-text);

  &:hover:not(.disabled) {
    background: var(--hcx-button-hover);
    background-color: var(--hcx-button-hover);
    color: var(--hcx-button-text);
  }

  &.disabled {
    background: #565D6D;
    background-color: #565D6D;
    color: #3A4253;
  }
}

.outlinedSizeSmall {
  padding: 2px 15px;
  color: var(--hcx-font-secondary);
  border-color: var(--hcx-blue);
  border-radius: 100px;
  font-size: 11px;
  height: fit-content;

  &:hover {
    background-color: var(--hcx-button-hover-outline-background);
    border-color: var(--hcx-button-hover);
  }

  &.disabled {
    background-color: var(--hcx-button-hover-outline-background);
    border-color: var(--hcx-button-hover);
    color: var(--hcx-font-secondary);
    opacity: 0.4;
  }
}

.containedSizeSmall {
  padding: 2px 15px;
  border-radius: 100px;
  text-transform: none;
  color: #000;
  background-color: var(--hcx-blue);

  &:hover:not(.disabled) {
    background: var(--hcx-button-hover);
    background-color: var(--hcx-button-hover);
  }

  &:disabled {
    background-color: var(--hcx-blue);
    color: #000;
    opacity: 0.4;
  }
}

.textPrimary {
  color: var(--hcx-font-primary);
}

.text {
  color: var(--hcx-blue);
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

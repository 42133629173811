.root {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-flow: row wrap;
  color: var(--hcx-font-primary);
}

.price {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.priceSmall {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 3px;
}

.progress {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
}

.progressUp {
  color: var(--hcx-alert-up);
}

.progressDown {
  color: var(--hcx-alert-down);
}

.infoItem {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.infoValue {
  @extend .priceSmall;
}

.infoTitle {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #A6A6A6;
}

@import 'src/styles/theme/theme';

$header-height: 115px;

.grid {
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.row > *:not(.resizer) {
  width: 100%;
}

@import 'src/styles/theme/theme';
@import 'src/styles/theme/utils';

$item-size: 165px;

.root {
  padding: $hcx-spacing * 2 $hcx-spacing;
}

.wrapper {
  display: grid;
  grid-auto-rows: $item-size;
  grid-template-areas: 'a a';
  grid-template-columns: 1fr 1fr;
  gap: $hcx-spacing;
  padding-inline: $hcx-spacing;
}

.item {
  background: var(--hcx-background);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    border: 1px solid var(--hcx-blue);
    cursor: pointer;
  }
}

.imageWrapper {
  width: 86px;
  height: 86px;
  margin-bottom: $hcx-spacing * 1.5;

  @include flex-center;
}

@import 'styles/theme/theme';
@import 'styles/colors';
@import '../../features/sponsor-application/sponsor-application.constants';

.rootContainer {
  position: fixed;
  box-shadow: -4px 0 14px rgba(0, 0, 0, 0.5);
  right: 0;
  /* stylelint-disable -- stylelint fix Min to min so comparing not working */
  max-width: Min($activity-panel-width, calc(100% - #{$sponsor-panel-width}));
}

.rootContainerPanel {
  right: $sponsor-panel-width;
}

.root {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  width: $activity-panel-width;
  flex-direction: column;
  transition: transform 1s, left 1s;
}

.sectionWrapper {
  margin-top: 50px;
  padding: $hcx-spacing * 1.5;
}

.sectionRoot {
  padding: $hcx-spacing * 1.5;
  background-color: var(--hcx-input-background);
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $hcx-spacing;
}

.dateText {
  color: var(--hcx-font-secondary);
}

.paginationBar {
  background-color: var(--hcx-card);
  position: fixed;
  bottom: 0;
  width: 380px;
  padding: $hcx-spacing $hcx-spacing * 2;
}

.itemsSelect {
  width: fit-content;
  margin-top: 0;
}

.rowDate {
  color: var(--hcx-font-secondary);
}

.rowAction {
  word-break: break-all;
}

@import 'src/styles/theme/theme';
@import 'src/styles/theme/utils';

.container {
  width: auto;
  position: relative;
  border-radius: 30px;
  margin-right: 2 * $hcx-spacing;
  background-color: #1F2531;

  &:hover {
    background-color: var(--hcx-alpha-background);
  }
}

.iconWrapper {
  padding: 0 1 * $hcx-spacing;
  height: 100%;
  position: absolute;
  pointer-events: none;

  @include flex-center;
}

.iconColor {
  color: var(--hcx-icon-primary);
}

.input {
  width: 16ch;
  color: var(--hcx-font-secondary);
  padding-top: 11px;
  padding-left: 4 * $hcx-spacing;
  font-size: 12px;
  transition: 0.2s ease-in-out;

  &:focus {
    width: 22ch;
  }
}

@import 'styles/theme/theme';

.root {
  padding: $hcx-spacing * 2 $hcx-spacing * 1.5;
  display: flex;
  flex-direction: column;
}

.switchers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: auto;

  & > div:first-child {
    margin-right: $hcx-spacing;
  }
}

.label {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: var(--hcx-font-secondary);
  margin-left: 4px;
  margin-bottom: 4px;
}

.sliderMark {
  width: 1px;
  height: 8px;
  background-color: #324F64;
}

.sliderRail {
  background-color: var(--hcx-blue);
  opacity: 0.24;
}

.sliderTrack {
  background-color: transparent;
}

.sliderThumb {
  box-shadow: none !important;
}

.slider {
  color: var(--hcx-blue);
}

.toggleText {
  display: flex;
  align-items: center;
  width: 2px;
  height: 14px;
  min-width: 2px;
  max-width: 2px;
  justify-content: center;
  overflow: visible;

  & > svg {
    min-width: 14px;
    min-height: 14px;
  }
}

.sizerWrapper {
  margin-top: $hcx-spacing * 2.4;
  margin-bottom: -13px;
}

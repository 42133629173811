.contentWrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.description {
  padding: 15px 15px 30px;
}

.textSecondary {
  color: var(--hcx-font-secondary);
  margin-right: 8px;
}

.bodyWrapper {
  flex: 1;
}

.footerPaginator {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
}

@import 'styles/theme/theme';

.root {
  flex: 1;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: var(--hcx-font-primary);
  margin: $hcx-spacing * 2;
  margin-top: $hcx-spacing * 2.2;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  /* stylelint-disable -- stylelint fix next line as display: box and it breaks ellipsing */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

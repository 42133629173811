@import 'styles/theme/theme';

.root {
  width: 100%;
}

.table {
  width: 100%;
  table-layout: auto;
  color: var(--hcx-font-primary);

  tr {
    cursor: pointer;
  }

  td {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    white-space: nowrap;

    .text {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .rank {
      color: var(--hcx-font-secondary);
      margin: 0 13px;
    }

    .rate {
      position: absolute;
      background: linear-gradient(90deg, #06374B 16.95%, #0675A8 100%);
      border-radius: 2px;
      top: 11px;
      bottom: 11px;
      left: 0;
      z-index: 0;
    }
  }

  th,
  td {
    vertical-align: middle;
    height: 47px;
  }

  th {
    position: relative;
    padding: 4px 0;
    padding-right: 14px;
  }

  tbody > tr:not(:last-child) > td {
    border-bottom: 1px dashed #2D3341;
  }
}

.selectedRow {

  .info {
    background-color: #298409;
  }

  td {

    & > .rate {
      background: linear-gradient(90deg, #298409 0%, #43BE17 100%);
    }

    .rank {
      color: inherit;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  width: 176px;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  clip-path:
    polygon(
      0 0,
      calc(100% - #{1.5 * $hcx-spacing}) 0,
      100% 54%,
      calc(100% - #{1.5 * $hcx-spacing}) 100%,
      0 100%
    );
  color: var(--hcx-font-primary);
  padding-right: 6%;
  border-radius: 5px;
}

.photo {
  width: 29px;
  height: 29px;
  margin-right: $hcx-spacing;
  border-radius: 50%;
  object-fit: contain;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.star {
  transform: translateY(-10px);
}

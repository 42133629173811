@import 'styles/theme/theme';

.root {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-auto-rows: minmax(50px, auto);
}

.card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .contract {
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: var(--hcx-font-primary);
    margin-top: 7px;
  }
}

.smallImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

@import 'src/styles/theme/theme';

.container {
  width: auto;
  position: relative;
  border-radius: 30px;
  margin-right: 2 * $hcx-spacing;
  background-color: #1F2531;

  &:hover {
    background-color: var(--hcx-alpha-background);
  }
}

.iconWrapper {
  padding: 0 1 * $hcx-spacing;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  width: 16ch;
  color: var(--hcx-font-secondary);
  padding: 8px 0 8px 40px;
  font-size: 12px;
  transition: 0.2s ease-in-out;

  &:focus {
    width: 22ch;
  }
}

.iconColor {
  color: var(--hcx-icon-primary);
}

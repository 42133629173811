@import 'styles/theme/utils';

.root {
  @include flex-center;

  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #293142;
  border-radius: 50%;
  color: var(--hcx-font-primary);

  & > svg {
    width: 18px;
    height: 18px;
  }
}

@import 'features/hcx-card/hcx-card-summary/hcx-card-summary-info/hcx-card-summary-info.module.scss';

.table {

  th {
    padding-right: 30px;
    min-width: 160px;
    max-width: 160px;
  }
}

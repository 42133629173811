@import 'styles/theme/theme';

.chart {
  width: 100%;
  table-layout: auto;
  color: var(--hcx-font-primary);

  tr {
    cursor: pointer;
  }

  th {
    padding-right: 1.5 * $hcx-spacing;
    text-align: end;
  }

  td {
    width: 100%;
    padding: $hcx-spacing 0;
  }

  tbody > tr:not(:last-child) > td {
    border-bottom: 1px dashed #2D3341;
  }

  tbody > tr:last-child > td {
    cursor: default;
  }

  .rate {
    background-color: var(--hcx-magenta);
    height: 14px;
  }
}

@import 'styles/theme/theme';
@import '../account-profile.constants';

.root {
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  right: 0;
  transform: translateX(100%);
  visibility: hidden;
  transition: transform 0.3s, right 0.3s;
  width: $account-profile-settings-width;
  /* stylelint-disable -- stylelint fix Min to min so comparing not working */
  max-width: Min($account-profile-settings-width, calc(100% - #{$account-profile-width}));
}

.opened {
  transform: translateX(0);
  visibility: visible;
}

.accountOpened {
  right: $account-profile-width;
}

.content {
  width: 100%;
  height: 100%;
  padding: $hcx-spacing * 2;
  padding-bottom: 0;
}

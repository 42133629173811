.description {
  padding: 15px 15px 30px;
}

.dashedRow {
  display: inline-flex;
  border-bottom: 1px dashed var(--hcx-input-border);
  gap: 5px;
}

.textColor {
  color: var(--hcx-font-marked);
}

@import 'styles/theme/theme';

.root {
  position: relative;
  min-width: $financial-card-width;
  height: 400px;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  transition: 0.5s;
  backface-visibility: hidden;
  background-color: var(--hcx-card);
  display: flex;
  flex-direction: column;
}

.back {
  transform: rotateY(180deg);
}

.rotated {

  .back {
    transform: rotateY(0deg);
  }

  .front {
    transform: rotateY(-180deg);
  }
}

@import 'styles/theme/theme';
@import './talent-application.constants';

.root {
  max-width: $talent-panel-width;
  width: 100%;
  right: 0;
  position: fixed;
  max-height: 100vh;
  height: 100%;
  display: flex;
  background-color: var(--hcx-background);
  flex-direction: column;
  padding: $hcx-spacing $hcx-spacing 0 $hcx-spacing;
  box-shadow: -4px 0 14px rgba(0, 0, 0, 0.5);
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.tabStyles :global(.MuiTab-iconWrapper) {
  margin-bottom: 19px;
}

.tab {
  text-transform: none;
  font-size: 14px;
  padding: 14px 25px;
  min-height: 0;
  min-width: 0;
  color: var(--hcx-font-secondary);
}

.indicatorSpan {
  max-width: 100px;
  width: 100%;
  min-width: 100%;
}

$hcx-main-font: 'Nunito Sans';
$hcx-spacing: 10px;
$athlete-card-width: 390px;
$financial-card-width: 505px;
$header-height: 125px;
$ticker-bar-height: 50px;
$trade-bar-height: 60px;
$menu-width: 36px;
$menu-open-width: 210px;

/* colors */
$hcx-color-blue: #6AD1FF;
$hcx-background-color-hover: #181E29;

.root {
  max-width: 100vw;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.button {
  min-width: 30px;
  height: 181px;
  background-color: var(--hcx-accordion-background);
  border-radius: 1px;
  padding: 0 !important;
}

.icons {
  color: var(--hcx-icon-primary);
}

.children {
  overflow-y: hidden;
  overflow-x: scroll;
  max-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.avatar {
  width: 175px;
  padding-right: 10px;
  object-fit: cover;
}

.descriptionWrapper {
  padding: 14px 20px 8px 10px;
  width: 195px;
}

.nameRow {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--hcx-font-primary);
}

.badge {
  margin: 4px;
  padding: 4px 12px;
  width: fit-content;
  background-color: var(--hcx-background-badge);
  color: var(--hcx-font-primary);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  border-radius: 2px;
  text-transform: capitalize;
}

.textSecondary {
  color: var(--hcx-font-secondary);
  font-size: 12px;
  line-height: 16px;
}

.carousel {
  width: calc(100% - 60px);
  margin-left: 30px;
}

@import 'src/styles/theme/theme';

.wrapper {
  width: 690px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mt2 {
  margin-top: $hcx-spacing * 2;
}

.mt4 {
  margin-top: $hcx-spacing * 4;
}

.ml2 {
  margin-left: $hcx-spacing * 2;
}

.ml1 {
  margin-left: $hcx-spacing;
}

.wa {
  width: auto;
}

.flex1 {
  flex: 1;
}

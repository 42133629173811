.positionAbsolute {
  position: absolute;
}

.modal {
  align-self: flex-start;
  margin-top: 100px;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.actionBtn:not(.cancel) {
  padding: 4px 18px !important;
}

.cancel {
  padding: 4px 0 !important;
  min-width: auto !important;
}

.justifyEnd {
  justify-content: flex-end;
}

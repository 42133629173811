@import 'styles/theme/theme';

.root {
  padding: $hcx-spacing * 2;
}

.header {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: $hcx-spacing * 2;

  svg {
    margin-right: $hcx-spacing / 2;
  }

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: var(--hcx-font-secondary);
    margin-left: auto;
  }
}

.bio {
  display: flex;
  width: 100%;
  min-width: 0;
  margin-bottom: $hcx-spacing * 2;
}

.imgWrapper {
  position: relative;
  width: min-content;
  width: 70px;
  height: 70px;
  margin-right: $hcx-spacing * 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;

  .name,
  .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .email {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--hcx-blue);
    margin: 2px 0 7px;
  }

  .link {
    font-weight: 400;
    cursor: pointer;
  }
}

.editBtnWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: var(--hcx-card);
  border: 1px solid #1E2430;

  svg {
    width: 14px;
    height: 14px;
  }
}

.commonText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-primary);
}

.descriptionTitle {
  font-weight: 600;
  margin-bottom: $hcx-spacing * 0.6;
}

.textSecondary {
  color: var(--hcx-font-secondary);
}

.actions {
  display: flex;
  align-items: center;
  margin-top: $hcx-spacing * 2;
}

.addRecoveryEmailBtn {
  margin: 0 auto 0 15px;
  padding: 3px 10px;
  min-width: fit-content;
  line-height: 15px;
}

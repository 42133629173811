@import 'styles/theme/theme';

.root {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  font-weight: 600;
  color: var(--hcx-font-primary);

  caption {
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    text-transform: capitalize;
    padding-bottom: $hcx-spacing / 2;
  }

  thead {
    font-size: 10px;
    line-height: 14px;
    color: var(--hcx-font-secondary);
    text-transform: uppercase;
  }

  tbody {
    font-size: 12px;
    line-height: 16px;
  }

  th,
  td {
    padding: $hcx-spacing;
    vertical-align: middle;
    text-align: center;

    &:first-child {
      padding-left: 2 * $hcx-spacing;
    }

    &:last-child {
      padding-right: 2 * $hcx-spacing;
    }

    &:nth-child(2) {
      padding-right: $hcx-spacing 1.5 * $hcx-spacing;
    }
  }

  tbody > tr > td {
    border-top: 1px solid var(--hcx-background);
  }

  tbody > tr:nth-child(odd) {
    background-color: var(--hcx-table-row-alt);
  }
}

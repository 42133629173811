@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@function if-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin default-font($important: false) {
  /* stylelint-disable-next-line scss/function-no-unknown */
  font-family: 'Nunito Sans', sans-serif if-important($important);
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@import 'styles/theme/theme';

.headingCellWrapper {
  display: flex;
  align-items: flex-end;
}

.headingTitle {
  display: block;
}

.headCell {
  padding: 10px 0;
}

.headingArrow {
  color: var(--hcx-table-heading);
}

.green {
  color: var(--hcx-alert-up);
}

.red {
  color: var(--hcx-alert-down);
}

.clickableRow {
  cursor: pointer;
}

@import 'styles/theme/theme';
@import './account-profile.shared';
@import './account-profile.constants';

.root {
  width: $account-profile-width;
  top: 0;
  right: -$account-profile-width;
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  transition: right 0.3s;
  display: flex;
  flex-direction: column;
}

.opened {
  box-shadow: -4px 0 14px rgba(0, 0, 0, 0.5);
  right: 0;
}

.customDivider {
  border-bottom-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.9);
}

.linksWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: $hcx-spacing * 3 $hcx-spacing $hcx-spacing $hcx-spacing * 5;
  flex: 1;
}

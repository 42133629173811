@import 'styles/theme/theme';
@import 'styles/theme/utils';

@mixin smaller-text {
  font-size: 10px;
  line-height: 14px;
}

.root {
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    text-align: left;
    vertical-align: top;
  }

  th {
    @include smaller-text;

    white-space: nowrap;
    color: #A6A6A6;
    text-transform: capitalize;
  }

  td {
    font-size: 12px;
    line-height: 16px;
    color: var(--hcx-font-primary);
    padding: $hcx-spacing * 1.5 0;
    padding-right: $hcx-spacing * 2;
    border-bottom: 1px solid var(--hcx-background);

    & > * {
      @include ellipsis;
    }
  }
}

.bodyRow {

  & > td:first-child {
    text-transform: capitalize;
  }

  & > td:nth-child(2) {
    max-width: 0;
    min-width: 0;
    width: 100%;
  }

  &:last-child {

    td {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  & > td:last-child {
    padding-right: 0;

    @include smaller-text;

    & > * {
      white-space: nowrap;
    }
  }
}

.up {
  color: var(--hcx-alert-up);
}

.down {
  color: var(--hcx-alert-down);
}

.athleteContract,
.athleteName {
  @include ellipsis;
}

.athleteContract {
  @include smaller-text;
}

.status {
  text-transform: capitalize;
}

@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  tr > td,
  tr > th {
    text-align: left;

    &:not(:first-child) {
      padding-left: $hcx-spacing;
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 50px;
    }
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: $hcx-spacing;
  }

  th {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #A6A6A6;
    text-transform: capitalize;
  }

  tbody {

    tr {

      & > td:first-child {
        padding-left: 0;
      }

      & > td:last-child {
        padding-right: 0;
      }

      &:last-child > td {
        padding-bottom: 0;
      }

      &:first-child > td {
        padding-top: 0;
      }

      & > td:nth-child(1) {
        width: 32px;
      }

      & > td:nth-child(2) {
        width: auto;
        min-width: 0;
        max-width: 150px;
        font-weight: 600;

        @include ellipsis;
      }
    }
  }
}

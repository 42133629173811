.root {
  color: var(--hcx-font-primary);
  text-align: left;
  vertical-align: top;
  width: 100%;

  caption,
  th,
  td {
    text-align: left;
    vertical-align: top;
    padding: 0;
  }

  caption {
    padding-bottom: 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  caption,
  th {
    color: var(--hcx-font-secondary);
  }

  th {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }

  tbody > tr {

    td {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      padding-top: 16px;
      color: var(--hcx-font-primary);
    }
  }
}

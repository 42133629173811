.infoTable {
  padding-top: 23px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 15px;
  }
}

.switcherWrapper {
  margin: 15px 0 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

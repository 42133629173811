@import 'src/styles/theme/theme';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: $hcx-spacing;
  min-width: $hcx-spacing;

  &.horizontal {
    width: 100%;
    height: $hcx-spacing;
    min-height: $hcx-spacing;
  }
}

.resizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4px;
  height: 45px;
  background: var(--hcx-resizer);
  border-radius: 60px;
  cursor: col-resize;
}

.horizontal .resizer {
  flex-direction: row;
  cursor: row-resize;
  width: 45px;
  height: 4px;
}

.dot {
  width: 2px;
  height: 2px;
  margin: 1px;
  background: var(--hcx-background);
}

.noSelect {
  user-select: none;
}

@import 'src/styles/theme/theme';

.row {
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: $hcx-spacing * 3;
  }
}

.field {
  width: 100%;
}

.field + .field {
  margin-left: $hcx-spacing * 2;
}

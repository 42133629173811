.description {
  color: var(--hcx-font-secondary);
  padding: 15px 15px 30px;
}

.excelIcon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-left: 35px;
}

.button {
  padding: 2px 30px;
}

.checkboxColor {
  color: var(--hcx-icon-checked);
  margin-right: 30px;
}

.secondaryText {
  display: inline-flex;
  gap: 5px;
  color: var(--hcx-font-secondary);
  font-size: 12px;
}

.wrapper {
  margin-left: 80px;
  margin-bottom: -5px;
  display: flex;
  align-items: baseline;
}

.inputFile {
  display: none;
}

.fileRow {
  display: inline-flex;
  align-items: baseline;
  margin: 0 0 0 60px;
}

.currentFileRow {
  display: inline-flex;
  align-items: center;
  margin: 26px 0 0 60px;
}

.description {
  padding: 15px 15px 30px;
  font-size: 14px;
  color: var(--hcx-font-secondary);
}

.descriptionSecondary {
  font-size: 12px;
  color: var(--hcx-font-secondary);
  margin-top: 10px;
  margin-bottom: 15px;
}

.checkboxRow {
  display: flex;
  align-items: flex-start;
  background: var(--hcx-accordion-background);
  border: 1px solid var(--hcx-radioselect-border);
  border-radius: 4px;
  padding: 5px 20px 5px 5px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

@import 'styles/theme/theme';
@import '../../column-configuration/constants';

.root {
  width: $athlete-card-width;
  margin-left: $hcx-spacing;
  padding: 2.5 * $hcx-spacing;
}

.title {
  margin-bottom: 1.5 * $hcx-spacing;
}

.athleteWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.5 * $hcx-spacing;
}

.nameWrapper {
  margin-left: 1.5 * $hcx-spacing;
}

.contractId {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 12px;
  background: #243F7A;
  border-radius: 2px;
  width: fit-content;
  margin: 4px 0 10px;
}

.firstRowWrapper {
  margin: 4 * $hcx-spacing 0 2 * $hcx-spacing 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.firstRowStatusWrapper {
  display: flex;
  align-items: center;
}

.offeringDetails,
.auctionSummary {
  color: var(--hcx-font-primary);
}

.auctionSummary {
  margin-bottom: 2 * $hcx-spacing;
}

.divider {
  margin: 3 * $hcx-spacing 0 2 * $hcx-spacing 0;
}

.active {
  color: var(--hcx-alert-up);
}

.scheduled {
  color: #8300C0;
}

.closed {
  color: var(--hcx-red);
}

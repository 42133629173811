@import 'styles/theme/theme';

.root {
  width: $athlete-card-width;
  min-width: $athlete-card-width;
  max-width: $athlete-card-width;
  padding: 0;
  background: var(--hcx-field);
  box-shadow: 0 8px 15px var(--hcx-tooltip-shadow);
  border-radius: 8px;
  overflow: hidden;
}

.priceLine {
  background-color: #171D2A;
}

.pointer {
  cursor: pointer;
}

@import 'styles/theme/theme';

.root {
  width: 100%;
  min-width: 100%;
  height: $trade-bar-height;
  display: flex;
  align-items: center;
  background-color: #212735;
  box-shadow: 0 -1px 0 rgba(5, 7, 10, 0.6), inset 0 2px 8px rgba(0, 0, 0, 0.35);
  padding: $hcx-spacing * 0.8;

  & > * {
    margin-right: $hcx-spacing * 2;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buySellBtn {
  padding: 3px 17.5px;
}

.walletBlock {
  min-width: 130px;
  margin-right: $hcx-spacing * 6;
}

.talentBlock {
  min-width: 205px;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  flex: 1;
  background-color: var(--hcx-card);
  height: 100%;
  margin-right: 0;

  &:hover {
    background-color: var(--hcx-card);
  }
}

.searchIcon {
  padding: 0 $hcx-spacing * 0.5 0 $hcx-spacing;

  svg {
    width: 18px;
    height: 18px;
  }

  &_selected {
    padding-left: 2px;
  }
}

.searchInput {
  height: 100%;
  width: 120px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-primary);
  text-overflow: ellipsis;

  &:focus {
    width: 120px;
  }
}

.searchResults {
  right: unset;
  left: 0;
}

.sharesBlock {
  min-width: 150px;
  max-width: 200px;
}

.photo {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
}

.selectRoot {
  border: none;

  :global(.MuiOutlinedInput-notchedOutline) {
    display: none;
  }
}

.goodUntilSelect {
  min-width: 82px;
  max-width: 82px;
  height: 30px;
  background-color: var(--hcx-card);
  border-radius: 100px;
  padding-left: 15px;
  padding-right: 5px;
}

.select {
  display: flex;
  align-items: center;
  padding: 0;
  padding-right: 28px !important;
}

.selectIcon {
  color: var(--hcx-font-secondary);
  width: 18px;
  height: 18px;
  font-size: 18px;
  right: 5px;
}

.selectPaper {
  background: var(--hcx-field);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.55);
  border-radius: 8px;
  border: 0;
  margin-left: $hcx-spacing;
  margin-top: $hcx-spacing;
}

.goodUntilSelectPaper {
  @extend .selectPaper;

  margin-left: $hcx-spacing * 3;
}

.selectPaper .selectList {
  padding-top: 0;
}

.selectMenuItem {
  padding: 7.5px 15px 7.5px 33px;
  position: relative;

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  &:hover {
    background: var(--hcx-card);
  }

  &:global(.Mui-selected)::before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--hcx-blue);
  }
}

.walletSelectPlaceholder,
.walletSelectValue {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.walletSelectPlaceholder {
  font-weight: 400;
  color: var(--hcx-font-secondary);
}

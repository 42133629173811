@import 'styles/theme/theme';
@import 'styles/theme/utils';

.root {
  width: 100%;
  height: 100%;

  @include flex-center;

  padding: 0.5 * $hcx-spacing;
  flex-direction: column;
}

.iconWrapper {
  margin-bottom: 2%;
  color: var(--hcx-font-secondary);
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--hcx-font-secondary);
  text-transform: capitalize;
}

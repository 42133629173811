@import 'styles/theme/theme';
@import 'styles/theme/utils';
@import '../account-profile.shared';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $hcx-spacing * 4 $hcx-spacing * 2 $hcx-spacing * 3;
}

.emailLabel {
  margin-top: 14px;
}

.email {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--hcx-blue);
  opacity: 0.9;
  display: block;
  max-width: 100%;

  @include ellipsis;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $hcx-spacing * 3;
  margin-top: $hcx-spacing * 3;
}

.isHidden {
  display: none;
}

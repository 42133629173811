.activeBtn {
  background-color: #2C3447 !important;
}

.menuList {
  padding: 0 !important;
}

.menuPaper {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.55);
  border-radius: 8px;
  overflow: hidden;
}

@import 'src/styles/theme/theme';

.titleRow {
  display: flex;
  align-items: baseline;
  margin-top: $hcx-spacing * 3.5;
  padding-left: $hcx-spacing * 3.5;
}

.totalValue {
  font-size: 22px;
  margin-left: $hcx-spacing * 2;
}

.tableTitleArrow {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.cell {
  vertical-align: middle;
}

.avatar {
  margin-right: $hcx-spacing * 3;
}

.actionsCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $hcx-spacing * 2;
  align-items: center;
}

.firstCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.defaultCBFormControlDisabled :global(.MuiFormControlLabel-label) {
  opacity: 0;
}

@import 'styles/theme/theme';

.accordion {
  background: transparent;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid var(--hcx-divider);

  &:not(.expanded):hover {
    background: #1B202C;
  }
}

.summary {
  padding: 0 $hcx-spacing;
}

.details {
  padding: 0 $hcx-spacing 24px 38px;
}

.expanded {
  background: linear-gradient(180deg, #1B202B 0%, rgba(27, 32, 43, 0) 100%);
}

.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 8px;
  height: 16px;
  color: var(--hcx-font-secondary);
  background: #212634;
  border-radius: 100px;
}

.summaryContent {
  justify-content: space-between;
  align-items: center;
}

.summaryIcon {
  background: transparent;
  margin-right: 4px;
}

.accordionDisabled {
  background-color: transparent !important;
}

.summaryDisabled {
  opacity: 1 !important;
}

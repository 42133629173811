@import '../account-profile-header/account-profile-header.module.scss';

.root {
  background-color: transparent;
}

.titleWrapper {
  display: flex;
}

.helpIcon {
  color: var(--hcx-icon-default);
  width: 14px;
  height: 14px;
  margin-left: 4px;
  cursor: help;
}

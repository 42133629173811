@import 'styles/theme/theme';
@import '../account-profile.shared';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $hcx-spacing * 3 $hcx-spacing * 5;
}

.colorBlue {
  color: var(--hcx-blue);
  cursor: pointer;
}

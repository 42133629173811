@import 'styles/colors';
@import '../account-profile.shared';

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 35px 45px 30px 50px;
}

.statusColor {
  color: var(--hcx-font-status);
}

.colorBlue {
  color: var(--hcx-blue);
  cursor: pointer;
}

.backgroundPending {
  background-color: var(--hcx-field);
}

.backgroundAccepted {
  padding: 11px 17px 24px 27px;
}

.firstPrice {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.greenPrice {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-alert-up);
}

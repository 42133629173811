@import 'styles/theme/theme';

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2 * $hcx-spacing;
  column-gap: 3.5 * $hcx-spacing;
}

.spanRow {
  grid-column: 1 / span 2;
}

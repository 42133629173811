@import 'styles/colors';

.wrapper {
  padding: 15px;
  background-color: var(--hcx-accordion-background);
}

.text {
  display: block;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  display: block;
  margin-bottom: 0;
}

@import 'styles/theme/theme';

.root {
  display: flex;
  justify-content: space-between;
  background-color: var(--hcx-card);
  color: var(--hcx-font-primary);
  padding: 5px $hcx-spacing 5px 2 * $hcx-spacing;
  min-height: 42px;
}

.left {
  display: flex;
  align-items: center;
}

.titleContainer {
  text-align: center;
  width: 250px;
  border-right: 2px solid var(--hcx-divider);
  padding: $hcx-spacing $hcx-spacing;
}

.symbolContainer {
  display: flex;
  align-items: center;
  padding: 0 $hcx-spacing;

  &:first-child {

    &::after {
      content: '';
      border-right: 1px solid var(--hcx-icon-primary);
      height: 100%;
      margin-left: calc(#{$hcx-spacing} * 2);
    }
  }
}

.athleteTooltip {
  width: $athlete-card-width;
  min-width: $athlete-card-width;
  max-width: $athlete-card-width;
  padding: 0;
  background: var(--hcx-field);
  box-shadow: 0 2px 15px var(--hcx-tooltip-shadow);
  border-radius: 8px;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

@mixin item-spacing {
  margin-left: $hcx-spacing;

  &:first-child {
    margin-left: 0;
  }
}

.item {
  padding: 7px $hcx-spacing 3px;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 2px;

  @include item-spacing;
}

.athleteItemWrapper {
  border-radius: 2px;

  &:hover {
    background-color: var(--hcx-field);
  }

  @include item-spacing;
}

.actions {
  display: flex;
  align-items: center;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  flex: 1;
  background-color: var(--hcx-search-background-alt);
  height: 100%;
  margin-right: 0;

  &:hover {
    opacity: 0.9;
  }
}

.searchIcon {
  padding: 0 $hcx-spacing * 0.5 0 $hcx-spacing;

  svg {
    width: 18px;
    height: 18px;
  }

  &_selected {
    padding-left: 2px;
  }
}

.searchInput {
  height: 100%;
  width: 120px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--hcx-font-primary);
  text-overflow: ellipsis;

  &:focus {
    width: 120px;
  }
}

.searchResults {
  right: unset;
  left: 0;
  z-index: 1000;
}

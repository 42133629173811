@mixin border-radius {

  &.small {
    border-radius: 2px;
  }

  &.medium {
    border-radius: 5px;
  }
}

.root {
  background-color: var(--hcx-background-custom-switcher);
  padding: 1px;
  min-height: fit-content;
  max-height: fit-content;
  height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  width: fit-content;

  @include border-radius;
}

.flexContainer {
  background-color: transparent;
  z-index: 0;
}

.indicator {
  height: 100%;
  border-radius: 5px;
  background: var(--hcx-background);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  @include border-radius;
}

.indicatorContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab {
  color: var(--hcx-font-primary);
  min-width: 35px;
  min-height: 22px;

  &.small {
    padding: 5px 14px;
  }

  &.medium {
    padding: 8px 20px;
  }
}

.selected {
  color: var(--hcx-font-primary);
}

.medium {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.small {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

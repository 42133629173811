@import 'styles/theme/theme';

.root {
  padding: 2 * $hcx-spacing;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-right: $hcx-spacing;
}

.content {
  display: flex;
  flex: 1;
}

.hw100 {
  height: 100%;
  width: 100%;
}

@import 'src/styles/theme/theme';

.root {
  min-width: $athlete-card-width;
  background: var(--hcx-card-gradient);
  margin-left: $hcx-spacing;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $hcx-spacing $hcx-spacing 0 $hcx-spacing * 2;
}

.description {
  margin-top: $hcx-spacing * 3;
  padding-inline: $hcx-spacing * 2;
}

.tabs {
  margin-top: $hcx-spacing * 2;
  padding-inline: $hcx-spacing * 2;
}

@import 'styles/colors';

.inviteForm {
  margin-top: 16px;
}

.addAnotherUser {
  color: var(--hcx-blue);
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 21px;
}

.invitationField {
  width: 100%;

  [class*='MuiInputBase-inputMultiline'] {
    height: 108px !important;
  }
}

.emailField {
  width: 228px;
}

.selectRole {
  width: 111px;
}

:root {
  --hcx-background: #0C0C18;
  --hcx-background-custom-switcher: #2F374A;
  --hcx-background-custom-switcher-darker: #222837;
  --hcx-background-invitations-block: #2A3D6C;
  --hcx-background-badge: #243F7A;
  --hcx-header: var(--hcx-background);
  --hcx-divider: var(--hcx-background);
  --hcx-field: #202633;
  --hcx-card: #161A24;
  --hcx-panel: var(--hcx-card);
  --hcx-blue: #6AD1FF;
  --hcx-button: var(--hcx-blue);
  --hcx-button-hover: #ADE5FF;
  --hcx-button-hover-outline-background: #30354A;
  --hcx-button-cancel: #3B0000;
  --hcx-button-border-cancel: #5B0000;
  --hcx-link: var(--hcx-button);
  --hcx-icon-primary: #6D7B99;
  --hcx-icon-default: #9E9EA3;
  --hcx-icon-secondary: var(--hcx-button);
  --hcx-icon-checked: #59D43C;
  --hcx-font-primary: #FFF;
  --hcx-font-primary-alpha-6: rgba(255, 255, 255, 0.6);
  --hcx-font-secondary: #AFB8CB;
  --hcx-font-table-header: #7E8EAF;
  --hcx-font-status: #CF7C00;
  --hcx-font-pending: #F39E1D;
  --hcx-font-marked: #FFCC6A;
  --hcx-font-raiting: #D79B00;
  --hcx-font-confirmation: #5DBA4D;
  --hcx-alert-up: #01FF39;
  --hcx-alert-up-alt: #00D82F;
  --hcx-alert-up-alpha-15: rgba(1, 255, 56, 0.15);
  --hcx-alert-down: #FF2B2B;
  --hcx-alert-down-alpha-15: rgba(255, 43, 43, 0.15);
  --hcx-alpha-background: rgba(31, 37, 49, 0.8);
  --hcx-button-text: #06202B;
  --hcx-accordion-background: #1B202B;
  --hcx-table-heading: var(--hcx-icon-primary);
  --hcx-table-border: var(--hcx-background);
  --hcx-resizer: #232938;
  --hcx-select-background: #262D3D;
  --hcx-search-background-alt: #1F2531;
  --hcx-select-content-background: var(--hcx-field);
  --hcx-select-content-divider: #2A3142;
  --hcx-column-config: var(--hcx-field);
  --hcx-column-config-hover: #1C222D;
  --hcx-table-row-alt: #1D2330;
  --hcx-tooltip-border: #3A4660;
  --hcx-tooltip-shadow: rgba(0, 0, 0, 0.9);
  --hcx-logo-blue: var(--hcx-blue);
  --hcx-logo-primary: var(--hcx-font-primary);
  --hcx-logo-secondary: #E4E4E4;
  --hcx-card-gradient: linear-gradient(180deg, rgba(41, 49, 66, 0.68) 0%, rgba(41, 49, 66, 0) 100%);
  --hcx-account-profile-header: #13161F;
  --hcx-magenta: #7B00DD;
  --hcx-green: #008B1E;
  --hcx-red: #F00;
  --hcx-dark-red: #3C0000;
  --hcx-cell-prefix: #3C4352;
  --hcx-incomplete-background: #212634;
  --hcx-input-background: #202735;
  --hcx-input-border: #3C455C;
  --hcx-input-border-hover: #576383;
  --hcx-input-background-error: #291818;
  --hcx-input-border-error: #AE0404;
  --hcx-input-text-error: #FF847C;
  --hcx-input-background-disabled: #181C29;
  --hcx-input-border-disabled: #292E41;
  --hcx-input-font-disabled: #464F65;
  --hcx-pending-badge: #1163AE;
  --hcx-radioselect-border: #3C435A;
}

@import 'src/styles/theme/theme';

.container {
  min-width: 390px;
  padding: $hcx-spacing * 1.5;
}

.titleRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.filters {
  margin-top: $hcx-spacing * 3;
}

@import 'styles/theme/theme';

$content-height: calc(100vh - #{$header-height + $hcx-spacing * 2});

.container {
  min-height: $content-height;
  display: flex;
  flex-flow: row nowrap;
}

.chartContainer {
  display: flex;
  flex-direction: column;
}

.chart {
  flex: 1;
}

.chartRow {
  min-height: 240px;
}

.root {
  position: relative;
  height: 100%;
  width: 100%;
}

.listHolder {
  display: grid;
  gap: $hcx-spacing;
  width: 100%;
}

.scrollbar {
  flex: 1;
  height: calc(100% - 52px - 4px) !important;
}

.pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 52px;
  background-color: var(--hcx-background-custom-switcher);
}

.root {
  height: 100%;
}

.thumb {
  background: #323D54;
  border-radius: 60px;
}

.view {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 4px);
}

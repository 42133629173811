@import 'styles/theme/theme';

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: $hcx-spacing;
  margin-bottom: $hcx-spacing * 1.5;
}

.annotationTextHeading {
  margin-bottom: $hcx-spacing;
  color: var(--hcx-font-primary);
}

.emailText {
  color: var(--hcx-blue);
  text-decoration: none;
  margin: 0 4px;
}

@import 'styles/theme/theme';

.root {
  max-width: 536px;
}

.backdrop {
  backdrop-filter: blur(4px);
}

.contentWrapper {
  padding: $hcx-spacing * 1.6 $hcx-spacing * 3.5 0;
}

.title,
.text {
  font-weight: 600;
  line-height: 18px;
}

.title {
  font-size: 18px;
  color: var(--hcx-font-primary);
  margin-bottom: $hcx-spacing * 1.3;
}

.text {
  font-size: 14px;
  color: var(--hcx-font-secondary);
}

ul.text {
  font-weight: 700;
  font-size: 12px;
  margin: $hcx-spacing * 3.5 0 $hcx-spacing * 5.3 0;
  list-style: square;
  padding-left: $hcx-spacing * 6.3;

  & > li:not(:last-child) {
    margin-bottom: $hcx-spacing * 1.3;
  }
}

.goPublicWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goPublicBtn {
  margin: 0 $hcx-spacing * 1.3 0 $hcx-spacing * 3.2;
  white-space: nowrap;
  line-height: 16px;
  padding: 4px 15px;
  min-width: fit-content;
}

.actions {
  width: 100%;
  padding: 0 $hcx-spacing * 3.5 0;

  a {
    font-weight: 400;
  }
}

.root {
  justify-content: flex-end;
  padding: 10px 36px 10px 25px;
}

.selected {
  background-color: inherit;
  position: relative;
  text-align: right;

  &::after {
    content: '';
    position: absolute;
    background: var(--hcx-button);
    width: 4px;
    height: 4px;
    right: 17px;
  }
}

.divider {
  border-bottom: 1px solid var(--hcx-select-content-divider);
}

.heading {
  font-size: 14px;
  font-weight: 700;
  color: var(--hcx-font-primary);
}

.description {
  padding: 10px 0 20px;
  color: var(--hcx-font-secondary);
  font-size: 12px;
}

.approvals {
  margin-top: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.approvalsRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}

.initialInput {
  width: 53px;
}

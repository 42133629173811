@import 'styles/theme/theme';

.contentWrapper {
  height: 100%;
  padding: $hcx-spacing 0 0 0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.bodyBlock {
  display: flex;
  flex-grow: 1;
}

.menuBlock {
  padding: 0 $hcx-spacing / 2;
}

.contentBlock {
  flex-grow: 1;
  height: 100%;
  width: calc(100% - 220px);
}

.checkIcon {
  color: var(--hcx-icon-checked);
}

.incomplete {
  background: var(--hcx-incomplete-background);
  border-radius: 100px;
  padding: 4px 15px;
  font-weight: 700;
  font-size: 8px;
  line-height: 9px;
}

.menuList {
  margin-left: -20px;

  ul {
    padding: 0 !important;
  }
}

.menuItem {
  padding: 10px 40px 10px 15px;
  background-color: #293244;

  &:hover {
    background-color: #212937;
  }
}

.permissionWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.noPointer {
  cursor: text;
}

.arrowExpand {
  color: var(--hcx-icon-default);
  width: 14px;
}

@import 'styles/theme/theme';

.root {
  width: 100%;
}

.card {
  width: 100%;
  margin-bottom: $hcx-spacing;

  &:last-child {
    margin-bottom: 0;
  }
}

.root {
  padding: 10px 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.role {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
  margin-right: auto;
}

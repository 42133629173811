@import 'src/styles/theme/theme';

.root {
  padding: 2.5 * $hcx-spacing;
  display: flex;
  flex-direction: column;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3 * $hcx-spacing;
  column-gap: 4 * $hcx-spacing;
  grid-template-areas:
    'number number'
    'mm yyyy'
    'cvv .';
}

.creditCard {
  grid-area: number;
}

.button {
  align-self: flex-end;
  margin-top: 7 * $hcx-spacing;
}

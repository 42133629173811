.root {
  animation-duration: 350ms;

  & > td {
    border-bottom: 1px solid var(--hcx-field);
    padding: 4px 5px;
  }

  &:last-child > td {
    border: none;
  }
}

.asc {
  animation-name: asc;

  & > td:first-child {
    color: var(--hcx-alert-down);
  }
}

.bid {
  animation-name: bid;

  & > td:last-child {
    color: var(--hcx-alert-up);
  }
}

@keyframes bid {

  0% {
    background-color: var(--hcx-alert-up-alpha-15);
  }

  100% {
    background-color: unset;
  }
}

@keyframes asc {

  0% {
    background-color: var(--hcx-alert-down-alpha-15);
  }

  100% {
    background-color: unset;
  }
}

@import 'src/styles/theme/theme';
@import 'src/styles/theme/utils';

.root {
  position: absolute;
  right: 0;
  top: 100%;
  background: var(--hcx-field);
  padding-bottom: $hcx-spacing;
  padding-left: $hcx-spacing;
  padding-right: $hcx-spacing;
  width: 527px;
  min-width: 100%;
  border-radius: 8px;
  z-index: 1000;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $hcx-spacing * 2;
}

.loading {
  @include flex-center;

  width: 100%;
  min-height: 100px;
}

.table {
  table-layout: auto;
  border-collapse: collapse;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--hcx-font-secondary);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.row {
  cursor: pointer;

  &:hover {
    background: var(--hcx-select-background);
  }

  & > td > span {
    display: block;
    min-width: 0;
    max-width: 100%;

    @include ellipsis;
  }

  & > td {
    padding-left: $hcx-spacing * 2;
  }

  & > .image {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    padding-left: 0;
  }

  .name {
    font-size: 14px;
    line-height: 19px;
    color: var(--hcx-font-primary);
    padding-left: $hcx-spacing;
    min-width: 150px;
    max-width: 150px;
  }

  .ticker {
    min-width: 50px;
    max-width: min-content;
  }

  & > .description {
    padding-right: $hcx-spacing;
    min-width: 150px;
    max-width: 150px;
    width: 100%;
  }
}

.notFound {
  @include flex-center;

  min-height: 137px;
}

.photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.root {
  display: flex;
  justify-content: space-between;
  gap: 7.4%;
}

.photoInput {
  width: 120px;
  height: 120px;
}

.textAreaInput {
  width: 100%;
}

.textAreaInput :global(.MuiInputBase-inputMultiline) {
  height: 108px !important;
}

.sideBlock {
  width: 50%;
}

.row {
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
}

.labelRow {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.legalName {
  width: 214px;
}

.middleName {
  width: 194px;
}

.streetInput {
  width: 253px;
}

.aptInput {
  width: 167px;
}

.dateInput {
  width: 151px;
}

.stateInput {
  width: 82px;
}

.countryInput {
  width: 126px;
}

@import 'styles/theme/theme';

@mixin input-basics {
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 11px;
  font-family: $hcx-main-font;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  border-radius: 2px;
  box-sizing: border-box;
}

.root {
  width: 100%;
  height: 100%;
  margin-bottom: 9px;
}

.input {
  @include input-basics;

  color: var(--hcx-font-primary);
  border: 1px solid var(--hcx-input-border);
  background: var(--hcx-input-background);

  &:disabled {
    background: var(--hcx-input-background-disabled);
    border: 1px solid var(--hcx-input-border-disabled);
    color: var(--hcx-input-font-disabled);
  }

  &:hover {
    border: 1px solid var(--hcx-input-border-hover);
  }

  &::placeholder {
    color: var(--hcx-icon-primary);
  }
}

.error {
  @include input-basics;

  background: var(--hcx-input-background-error);
  border: 1px solid var(--hcx-input-border-error);
  color: var(--hcx-input-text-error);

  &:hover {
    border: 1px solid var(--hcx-input-border-error);
  }
}

.errorText {
  position: absolute;
  font-weight: 400;
  font-size: 10px;
  font-family: $hcx-main-font;
  color: var(--hcx-input-text-error);
}

.anchorOriginTopLeft {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: inherit;
}

.selectPhoto {

  & > input {
    display: none;
  }

  & > button {
    background-color: transparent;
    border-radius: 1px;
    width: 118px;
    height: 118px;
    cursor: pointer;

    &:disabled {
      cursor: none;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.avatarRoot {
  border-radius: 1px;
}

.avatarBackground {
  background-color: transparent;
  background-image: none;
  color: transparent;
}

.sizeAvatar {
  width: 118px;
  height: 118px;
}

@import 'styles/theme/theme';

.panel {
  padding: 2 * $hcx-spacing 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panelItem {
  padding: 0 2 * $hcx-spacing;
}

.headerWrapper,
.headerLeft {
  display: flex;
  align-items: center;
}

.headerWrapper {
  margin-bottom: 2 * $hcx-spacing;
}

.headerLeft {
  flex: 1;
}

.switcher {
  margin-left: 2rem;
}

.arrowButton {
  margin-left: $hcx-spacing;
  color: #FFF;
  transform: rotate(180deg);
}

.divider {
  margin: 2 * $hcx-spacing 0 $hcx-spacing 0;
}

.collapsedIcon {
  transform: rotate(180deg);
}

.icon {
  margin-right: $hcx-spacing / 2;
  height: 18px;
  width: 18px;
}

@import 'styles/theme/theme';

.root {
  padding: $hcx-spacing * 2 $hcx-spacing * 1.5;
  padding-top: $hcx-spacing;
}

.header {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: $hcx-spacing * 2;

  svg {
    margin-right: $hcx-spacing / 2;
  }
}

.tabs {
  margin-left: auto;
}

.totalWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 23px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 18px;

  .link {
    margin-left: 18px;
    font-weight: 400;
    cursor: pointer;
  }
}

.chartWrapper {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(
      0deg,
      rgba(106, 209, 255, 0) -0.35%,
      rgba(106, 209, 255, 0.115) 30.49%
    );
}

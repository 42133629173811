.select {
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
}

.disabled {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.5) !important;
}

.icon {
  color: var(--hcx-icon-default);
}

.nativeInput {
  display: none;
}

.rootItem {
  margin: 0;
  width: 100%;
  color: var(--hcx-font-secondary);
}

.selectedItem {
  color: var(--hcx-font-primary);
}

.menuPaper {
  background-color: var(--hcx-select-content-background);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.55);
  border-radius: 8px;
  border: none;
  margin-top: 3px;
  max-height: 30vh;
}

.menuPaper .menuList {
  padding-top: 0;
  text-transform: capitalize;
}

.inputLabel {
  margin-bottom: 7px;
  display: flex;
  gap: 8px;
}

.selectRoot {
  border-radius: 2px;
  height: 29px;
  border: 1px solid var(--hcx-input-border);
  color: var(--hcx-font-primary);
  background: var(--hcx-input-background);
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 0 0 0 15px;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--hcx-input-border-hover);
    background-color: var(--hcx-input-background) !important;
    outline: none;
  }
}

.errorRoot {
  border-radius: 2px;
  height: 29px;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 0 0 0 15px;
  background: var(--hcx-input-background-error);
  border: 1px solid var(--hcx-input-border-error);
  color: var(--hcx-input-text-error);

  &:hover {
    border: 1px solid var(--hcx-input-border-error);
  }
}

.errorText {
  position: absolute;
  font-weight: 400;
  font-size: 10px;
  color: var(--hcx-input-text-error);
}

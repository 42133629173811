@import 'styles/theme/theme';

.root {
  width: 100%;
  margin: 2px 0 8px;
  background-color: var(--hcx-background-invitations-block);
  padding: 6px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonClose {
  padding: 3px;
  color: var(--hcx-font-primary);
}

.colorBlue {
  color: var(--hcx-blue);
  cursor: pointer;
}

@import 'styles/theme/theme';
@import './sponsor-management.constants';

.root {
  box-shadow: -4px 0 14px rgba(0, 0, 0, 0.5);
  right: 0;
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  width: $sponsor-panel-width;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  position: fixed;
  background-color: var(--hcx-card);
  width: $sponsor-panel-width;
  border-bottom: 2px solid #000;
  padding: 20px 20px 0;
}

.footer {
  position: fixed;
  background-color: var(--hcx-card);
  bottom: 0;
  width: 380px;
  padding: $hcx-spacing $hcx-spacing * 2;
}

.content {
  margin-top: 120px;
  padding: 20px;
  overflow-y: auto;
}

.customDivider {
  border-bottom-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.9);
}

.tabStyles {
  align-self: flex-end;
}

.tab {
  font-size: 14px;
  padding: 15px 35px;
}

.indicatorSpan {
  min-width: 100px;
}
